<!-- ************************************** Item Netflix ********************************************************* -->
<p *ngIf="itemStream" class="itemContent">
    <span class="itemTitle">
        <span class="titleSize" title="{{entities.decodeHTML(utf8.decode(itemStream.title))}}" *ngIf="itemStream.title.length <= 12">
            {{entities.decodeHTML(utf8.decode(itemStream.title))}}</span>
        <span class="titleSize" title="{{entities.decodeHTML(utf8.decode(itemStream.title))}}" *ngIf="itemStream.title.length > 12">
            {{entities.decodeHTML(utf8.decode(itemStream.title)).substr(0,12).replace("''", "'")}}...</span>
            <br>
            <span *ngIf="itemStream.category=='2' && itemStream.newSeason=='0'"
            class="categorySerie">{{ 'lamentaliste.netflix.serie' | translate }}</span>
            <span *ngIf="itemStream.category=='1'" class="categoryFilm">{{ 'lamentaliste.netflix.film' | translate }}</span>
            <span *ngIf="itemStream.newSeason=='1'"
            class="categoryNewSeason">&nbsp;{{ 'lamentaliste.netflix.newSeason' | translate }}&nbsp;</span>
            <br>
    <a *ngIf="itemStream.tmdbId != ''" href="#/detailMovie?id={{itemStream.tmdbId}}&category={{itemStream.category}}" target="_blank">            
        <img class="cardimg" title="{{entities.decodeHTML(utf8.decode(itemStream.title))}}" src="{{itemStream.image}}">
    </a>
    <img *ngIf="itemStream.tmdbId == ''" class="cardimg" title="{{entities.decodeHTML(utf8.decode(itemStream.title))}}" src="{{itemStream.image}}">
    </span>
        <span *ngIf="itemStream.rating > 0">
        <br>
        <span>Note Imdb</span>
          <span>
            <span *ngIf="itemStream.rating > 6 && itemStream.rating > 0" class="displayImdbRatingTop">
              {{itemStream.rating}}
            </span>
            <span *ngIf="itemStream.rating < 4 && itemStream.rating > 0" class="displayImdbRatingFlop">
              {{itemStream.rating}}
            </span>
            <span *ngIf="itemStream.rating < 6 && itemStream.rating > 4" class="displayImdbRating">
              {{itemStream.rating}}
            </span>
         </span>
         </span>
    <br>
    <span *ngIf="itemStream.country.includes('ZA')"><img title="Afrique du Sud" class="country"
        [src]="'assets/country/south-africa.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('AL')"><img title="Albanie" class="country"
        [src]="'assets/country/AL.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('DE')"><img title="Allemagne" class="country"
        [src]="'assets/country/DE.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('AR')"><img title="Argentine" class="country"
        [src]="'assets/country/AR.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('AU')"><img title="Australie" class="country"
        [src]="'assets/country/AU.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('BE')"><img title="Belgique" class="country"
        [src]="'assets/country/BE.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('BR')"><img title="Brésil" class="country"
        [src]="'assets/country/BR.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('CA')"><img title="Canada" class="country"
        [src]="'assets/country/CA.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('CH')"><img title="Chili" class="country"
        [src]="'assets/country/CH.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('CN')"><img title="Chine" class="country"
        [src]="'assets/country/CN.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('CL')"><img title="Colombie" class="country"
        [src]="'assets/country/CL.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('KR')"><img title="Corée du Sud" class="country"
        [src]="'assets/country/KR.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('AE')"><img title="Emirats Arabes Unis" class="country"
        [src]="'assets/country/AE.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('ES')"><img title="Espagne" class="country"
        [src]="'assets/country/SP.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('US')"><img title="Etats-Unis" class="country"
        [src]="'assets/country/US.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('FJ')"><img title="Fiji" class="country"
        [src]="'assets/country/FJ.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('FR')"><img title="France" class="country"
        [src]="'assets/country/FR.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('GE')"><img title="Géorige" class="country"
        [src]="'assets/country/GE.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('HK')"><img title="Hong Kong" class="country"
        [src]="'assets/country/HK.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('IN')"><img title="Inde" class="country"
        [src]="'assets/country/IN.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('JP')"><img title="Japon" class="country"
        [src]="'assets/country/JP.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('PY')"><img title="Paraguay" class="country"
        [src]="'assets/country/PY.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('PH')"><img title="Philippines" class="country"
        [src]="'assets/country/PH.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('PL')"><img title="Pologne" class="country"
        [src]="'assets/country/PL.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('PR')"><img title="Portugal" class="country"
        [src]="'assets/country/PR.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('CZ')"><img title="République Tchèque" class="country"
        [src]="'assets/country/CZ.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('RO')"><img title="Roumanie" class="country"
        [src]="'assets/country/RO.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('GB')"><img title="Royaume-Uni" class="country"
        [src]="'assets/country/EN.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('SE')"><img title="Suède" class="country"
        [src]="'assets/country/SE.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('TH')"><img title="Thaïlande" class="country"
        [src]="'assets/country/TH.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('TR')"><img title="Turquie" class="country"
        [src]="'assets/country/TR.png'">&nbsp;</span>
    <span *ngIf="itemStream.country.includes('UY')"><img title="Uruguay" class="country"
        [src]="'assets/country/UY.png'">&nbsp;</span>


</p>
<!-- ************************************** Item liste ********************************************************* -->
<p *ngIf="item" class="itemContent">
    <span class="itemTitle">
        <span class="titleSize" title="{{item.Titre}}" *ngIf="item.Titre.length <= 12">
            {{item.Titre.replace("''", "'")}}</span>
        <span class="titleSize" title="{{item.Titre}}" *ngIf="item.Titre.length > 12">
            {{item.Titre.substr(0,12).replace("''", "'")}}...</span>
            <br>
    <a href="#/detailMovie?id={{item.image}}&listId={{listId}}&category={{item.categorie}}"><img class="cardimg" title="{{item.Titre}}"
            src="assets/posters/{{item.image}}.jpg"></a>
    </span>
</p>