<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel">
      <h1>{{ 'lamentaliste.games.extract' | translate }}</h1>
      {{'lamentaliste.games.extract.description' | translate }}
      <br>
      <div *ngIf="showOri" style="text-align: center">
          <img src="assets/extract/{{original}}"/>
        </div>
        <br>
      <lamentaliste-search [isQuiz]="true" [showAddMovie]="true" [showAddTV]="false"
      [showAddPerson]="false" (uploaded)="checkProposition($event)"></lamentaliste-search>
      <br>
      <div *ngIf="image" style="text-align: center">
        <img src="assets/extract/{{image}}"/>
      </div>
    </div>
  </div>
</div>