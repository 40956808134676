import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lamentaliste-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  @Input() item: any;
  @Input() itemStream: any;
  @Input() listId: string;

  entities = require("entities");
  //utf8 = require("utf8");
  utf8 = require("wtf-8");


  constructor() {}

  ngOnInit() {}
}
