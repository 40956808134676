<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="generalSearch"
    [style.background]="showAddMulti ? 'url(/assets/background/backMovie.jpg)' :
                            showAddMovie ? 'url(/assets/background/backMovie.jpg)' : 
                            showAddTV ? 'url(/assets/background/backTV.jpg)' : 
                            'url(/assets/background/backPerson.jpg)'">
    &nbsp;
    <button *ngIf="!isQuiz && !isNormalSearch" class="menuIcon" (click)="onClose()" mat-mini-fab color="primary">
        <mat-icon>close</mat-icon>
    </button>
    &nbsp;&nbsp;
    <div *ngIf="isNormalSearch">
        <button title="{{ 'lamentaliste.search' | translate }}" class="menuIcon" (click)="onSearchTypeButton(0)"
            mat-mini-fab color="primary">
            <mat-icon [ngClass]="{'notSelected': !showAddMulti, 'selected':showAddMulti }">search</mat-icon>
        </button>&nbsp;
        <button title="{{ 'lamentaliste.addMovie' | translate }}" class="menuIcon" (click)="onSearchTypeButton(1)"
            mat-mini-fab color="primary">
            <mat-icon
            [ngClass]="{'notSelected': !showAddMovie, 'selected':showAddMovie }">movie</mat-icon>
        </button>&nbsp;
        <button title="{{ 'lamentaliste.addSerie' | translate }}" class="menuIcon" (click)="onSearchTypeButton(2)"
            mat-mini-fab color="primary">
            <mat-icon [ngClass]="{'notSelected': !showAddTV, 'selected':showAddTV }">tv</mat-icon>
        </button>&nbsp;
        <button title="{{ 'lamentaliste.addPerson' | translate }}" class="menuIcon"
        (click)="onSearchTypeButton(3)"
            mat-mini-fab color="primary">
            <mat-icon [ngClass]="{'notSelected': !showAddPerson, 'selected':showAddPerson }">person</mat-icon>
        </button>
        &nbsp;&nbsp;
    </div>
    <mat-form-field *ngIf="showAddMulti">
        <input [(ngModel)]="queryMulti" (input)="updateSearchMulti()" type="text" matInput
            placeholder=" {{ 'lamentaliste.search' | translate }} ">
    </mat-form-field>
    <mat-form-field *ngIf="showAddMovie">
        <input [(ngModel)]="queryMovie" (input)="updateSearchMovie()" type="text" matInput
            placeholder=" {{ 'lamentaliste.addMovie' | translate }} ">
    </mat-form-field>
    <mat-form-field *ngIf="showAddTV">
        <input [(ngModel)]="querySerie" (input)="updateSearchSerie()" type="text" matInput
            placeholder=" {{ 'lamentaliste.addSerie' | translate }} ">
    </mat-form-field>
    <mat-form-field *ngIf="showAddPerson">
        <input [(ngModel)]="queryPerson" (input)="updateSearchPerson()" type="text" matInput
            placeholder=" {{ 'lamentaliste.addPerson' | translate }} ">
    </mat-form-field>
    <div *ngIf="result != undefined && result.length == 0" class="resultItem">
        {{ 'lamentaliste.noResult' | translate }}
    </div>
    <!------------------------- Multitype search -------------------------------------------------->
    <div *ngIf="result != undefined && result.length > 0 && showAddMulti" class="resultItem">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" class="searchTab">
            <!-- Poster Column -->
            <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef class="searchTabHeader"> </th>
                <td mat-cell *matCellDef="let element" class="searchTabImage">
                    <div *ngIf="element.media_type == 'movie'">
                        <a href="#/detailMovie?id={{element.id}}&category=1"
                        target="blank">
                            <img mat-card-img class="cardimg" src="http://image.tmdb.org/t/p/w92{{element.poster_path}}"
                            (error)="handleBrokenImage($event)">
                        </a>
                    </div> 
                    <div *ngIf="element.media_type == 'tv'">
                        <a  href="#/detailMovie?id={{element.id}}&category=2"
                        target="blank">
                            <img mat-card-img class="cardimg" src="http://image.tmdb.org/t/p/w92{{element.poster_path}}"
                            (error)="handleBrokenImage($event)">
                        </a>
                    </div> 
                    <div *ngIf="element.media_type == 'person'">
                        <img mat-card-img class="cardimg" src="http://image.tmdb.org/t/p/w92{{element.profile_path}}"
                        (error)="handleBrokenImage($event)" (click)="nolink()">
                    </div> 
                </td>
              </ng-container>

            <!-- Year Column -->
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef class="searchTabHeader"> Catégorie </th>
              <td mat-cell *matCellDef="let element" class="searchTabImage">
                <div *ngIf="element.media_type == 'tv'">
                    <mat-icon>tv</mat-icon>
                </div> 
                <div *ngIf="element.media_type == 'movie'">
                    <mat-icon>movie</mat-icon>
                </div> 
                <div *ngIf="element.media_type == 'person'">
                    <mat-icon>person</mat-icon>
                </div> 
            </td>
            </ng-container>
          
            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef class="searchTabHeader"> Titre </th>
              <td mat-cell *matCellDef="let element" class="searchTabTitle">
                <div *ngIf="element.first_air_date != null">
                    {{element.name}}&nbsp;({{element.first_air_date.substring(0,4)}})
                </div> 
                <div *ngIf="element.release_date != null">
                    {{element.title}}&nbsp;({{element.release_date.substring(0,4)}})
                </div> 
                <div *ngIf="element.media_type == 'person'">
                    {{element.name}}
                </div> 
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>          
   
    </div>


    <!------------------------- Singletype search ------------------------------------------------->
    <div *ngIf="result != undefined && result.length > 0 && !showAddMulti" class="resultItem">
        <div fxLayout="row">
            <div flex="30" class="flexHeight">
                <mat-card>
                    <a *ngIf="showAddMovie" href="#/detailMovie?id={{result[searchIndex].id}}&category=1"
                        target="blank">
                        <img mat-card-img class="cardimg" *ngIf="result[searchIndex].poster_path"
                            src="http://image.tmdb.org/t/p/w342{{result[searchIndex].poster_path}}">
                    </a>
                    <a *ngIf="showAddTV" href="#/detailMovie?id={{result[searchIndex].id}}&category=2" target="blank">
                        <img mat-card-img class="cardimg" *ngIf="result[searchIndex].poster_path"
                            src="http://image.tmdb.org/t/p/w342{{result[searchIndex].poster_path}}">
                    </a>
                    <a *ngIf="showAddPerson" target="blank">
                        <img mat-card-img class="cardimg" *ngIf="result[searchIndex].profile_path"
                            src="http://image.tmdb.org/t/p/w342{{result[searchIndex].profile_path}}">
                    </a>
                    <mat-card-actions>
                        <button mat-button *ngIf="!isNormalSearch"
                            (click)="addClicked()">Ajouter</button>&nbsp;{{searchIndex+1}}/{{result.length}}
                    </mat-card-actions>
                </mat-card>

            </div>
            <div class="flex" flex="70">
                <div class="buttonbigsize">
                    <div class="searchButtons">
                        <button mat-fab color="primary" (click)="firstClicked()">
                            <mat-icon>first_page</mat-icon>
                        </button>
                        &nbsp;
                        <button mat-fab color="primary" (click)="backClicked()">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                        &nbsp;
                        <div class="searchTitle" *ngIf="showAddMovie">
                            {{result[searchIndex].title}}&nbsp;({{result[searchIndex].release_date.substring(0,4)}})
                        </div>
                        <div class="searchTitle" *ngIf="showAddTV">
                            {{result[searchIndex].name}}&nbsp;({{result[searchIndex].first_air_date.substring(0,4)}})
                        </div>
                        <div class="searchTitle" *ngIf="showAddPerson">
                            {{result[searchIndex].name}}
                        </div>
                        &nbsp;

                        <button mat-fab color="primary" (click)="forwardClicked()">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                        &nbsp;
                        <button mat-fab color="primary" (click)="lastClicked()">
                            <mat-icon>last_page</mat-icon>
                        </button>
                        <br>
                    </div>
                </div>
                <div class="buttonsmallsize">
                    <div>
                        <div class="searchTitle" *ngIf="showAddMovie">
                            {{result[searchIndex].title}}&nbsp;({{result[searchIndex].release_date.substring(0,4)}})
                        </div>
                        <div class="searchTitle" *ngIf="showAddTV">
                            {{result[searchIndex].name}}&nbsp;({{result[searchIndex].first_air_date.substring(0,4)}})
                        </div>

                        <button mat-mini-fab color="primary" (click)="firstClicked()">
                            <mat-icon>first_page</mat-icon>
                        </button>
                        &nbsp;
                        <button mat-mini-fab color="primary" (click)="backClicked()">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                        &nbsp;
                        <button mat-mini-fab color="primary" (click)="forwardClicked()">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                        &nbsp;
                        <button mat-mini-fab color="primary" (click)="lastClicked()">
                            <mat-icon>last_page</mat-icon>
                        </button>
                        <br>
                    </div>
                    <div class="searchTitle" *ngIf="showAddPerson">
                        {{result[searchIndex].name}}
                    </div>
                    &nbsp;

                    <button mat-fab color="primary" (click)="forwardClicked()">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                    &nbsp;
                    <button mat-fab color="primary" (click)="lastClicked()">
                        <mat-icon>last_page</mat-icon>
                    </button>
                    <br>

                </div>
                <div *ngIf="!showAddPerson" class="resume">
                    <div *ngIf="result[searchIndex].overview">
                        {{ 'lamentaliste.resume' | translate }} : {{result[searchIndex].overview}}
                    </div>
                    <div *ngIf="!result[searchIndex].overview">
                        {{ 'lamentaliste.noResume' | translate }}
                    </div>
                </div>
                <div *ngIf="showAddPerson" class="resume">
                    <div *ngIf="personDetails.biography">
                        {{ 'lamentaliste.resume' | translate }} : <b>{{personDetails.biography}}</b>
                    </div>
                    <div *ngIf="!personDetails.biography">
                        {{ 'lamentaliste.noResume' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>