<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel friends">
      <h1>Dernières sorties</h1>
      <div *ngIf="filterView0 == '1'" class="titleImage"><img src="assets/netflix.jpg"></div>
      <div *ngIf="filterView0 == '2'" class="titleImage"><img src="assets/primevideo.jpg"></div>
      <mat-button-toggle-group [(ngModel)]="filterView0" (change)="onFilterPlatformChange()" name="filterView0"
      aria-label="Font Style">
      <mat-button-toggle value="1">Netflix</mat-button-toggle>
      <mat-button-toggle value="2">Amazon Prime</mat-button-toggle>
    </mat-button-toggle-group>


      <p *ngIf="filterView0 == '1'">
        <mat-button-toggle-group [(ngModel)]="filterView" (change)="onFilterChange()" name="filterView1"
          aria-label="Font Style">
          <mat-button-toggle value="1">{{ 'lamentaliste.netflix.filter.all' | translate }}</mat-button-toggle>
          <mat-button-toggle value="2">{{ 'lamentaliste.netflix.filter.movies' | translate }}</mat-button-toggle>
          <mat-button-toggle value="3">{{ 'lamentaliste.netflix.filter.series' | translate }}</mat-button-toggle>
          <mat-button-toggle value="4">{{ 'lamentaliste.netflix.filter.deleted' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
      </p>
      <p *ngIf="filterView0 == '2'">
        <mat-button-toggle-group [(ngModel)]="filterViewPrime" (change)="onFilterChangePrime()" name="filterView2"
          aria-label="Font Style">
          <mat-button-toggle value="1">{{ 'lamentaliste.netflix.filter.all' | translate }}</mat-button-toggle>
          <mat-button-toggle value="2">{{ 'lamentaliste.netflix.filter.movies' | translate }}</mat-button-toggle>
          <mat-button-toggle value="3">{{ 'lamentaliste.netflix.filter.series' | translate }}</mat-button-toggle>
          <mat-button-toggle value="4">{{ 'lamentaliste.netflix.filter.deleted' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
      </p>
<!--
      <p>
        <a mat-raised-button href="#/listContent?listId=5f6fd99807db4" target="_blank">{{ 'lamentaliste.netflix.love' | translate }}</a>
        <a mat-raised-button href="#/listContent?listId=5f6fd9a4c30b1" target="_blank">{{ 'lamentaliste.netflix.ok' | translate }}</a>
        <a mat-raised-button href="#/listContent?listId=5f6fd9abaf8dc" target="_blank">{{ 'lamentaliste.netflix.seemsokbutno' | translate }}</a>
      </p>
-->

      <!-- Vue liste -->
      <mat-card *ngIf="displayView == '2'">
        <div fxLayout="row wrap" fxLayoutGap="20px" class="displayList">
          <ng-container *ngFor="let item of netflixList">
            <div *ngIf="isNewDate(item.onDate)" fxFlex="0 1 calc(98% - 32px)" >
              <mat-card-title class="dateTitle">Ajouté le {{item.onDate  | date:'dd/MM/yyyy'}}</mat-card-title>
            </div>
            <div id="{{item.image}}" class="displayList" fxFlex="0 1 calc(20% - 32px)">
              <mat-card class="mat-card">
                <lamentaliste-list-item [itemStream]="item"></lamentaliste-list-item>
                <span *ngIf="item.isPrimeVideo == 1 && item.link != ''">
                  <a href="{{item.link}}" target="_blank">
                    voir sur <img class="logoImage" src="assets/primevideo.jpg">
                  </a>
                </span>
                <span *ngIf="item.isPrimeVideo == 0">
                  <a href="https://www.netflix.com/title/{{item.netflixId}}" target="_blank">
                    voir sur <img class="logoImage" src="assets/netflix.jpg">
                  </a>
                </span>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </mat-card>


      <!-- Vue tableau -->
      <mat-card *ngIf="displayView == '1'">
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 matTable">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Date </th>
            <td mat-cell *matCellDef="let list" class="displayContent"> {{list.onDate  | date:'dd/MM/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Titre </th>
            <td mat-cell *matCellDef="let list">
              <a href="#/detailMovie?id={{list.tmdbId}}&category={{list.category}}" *ngIf="list.frenchTitle != ''"
                target="_blank">
                <span class="displayList2">{{utf8.decode(entities.decodeHTML(list.frenchTitle))}}</span>
              </a>
              <span *ngIf="list.frenchTitle == ''" class="displayListWo">{{entities.decodeHTML(list.title)}}</span>
              <span *ngIf="list.released"> ({{list.released}})</span>
              <div class="categoryFilm">{{list.runtime}}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Type </th>
            <td mat-cell *matCellDef="let list" class="displayContent">
              <span *ngIf="list.category=='2' && list.newSeason=='0'"
                class="categorySerie">{{ 'lamentaliste.netflix.serie' | translate }}</span>
              <span *ngIf="list.category=='1'" class="categoryFilm">{{ 'lamentaliste.netflix.film' | translate }}</span>
              <span *ngIf="list.newSeason=='1'"
                class="categoryNewSeason">&nbsp;{{ 'lamentaliste.netflix.newSeason' | translate }}&nbsp;</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let list">
              <span *ngIf="list.tmdbId">
                <a href="#/detailMovie?id={{list.tmdbId}}&category={{list.category}}" target="_blank">
                  <img *ngIf="list.image" class="cardimg" title="{{list.title}}" [src]="list.image"
                    onError="this.src='assets/imgError.png'" />
                </a>
              </span>
              <span *ngIf="!list.tmdbId">
                <img *ngIf="list.image" class="cardimgWo" title="{{list.title}}" [src]="list.image"
                  onError="this.src='assets/imgError.png'" />
              </span>
          </ng-container>
          <!-- <ng-container matColumnDef="detail">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                      <td mat-cell *matCellDef="let list">
                        <span *ngIf="list.tmdbId">
                          <a href="#/detailMovie?id={{list.tmdbId}}&category={{list.category}}">
                            Voir détail
                          </a>
                        </span>
                  </ng-container>-->
          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Pays </th>
            <td mat-cell *matCellDef="let list">
              <span *ngIf="list.country.includes('ZA')"><img title="Afrique du Sud" class="country"
                  [src]="'assets/country/south-africa.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('AL')"><img title="Albanie" class="country"
                  [src]="'assets/country/AL.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('DE')"><img title="Allemagne" class="country"
                  [src]="'assets/country/DE.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('AR')"><img title="Argentine" class="country"
                  [src]="'assets/country/AR.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('AU')"><img title="Australie" class="country"
                  [src]="'assets/country/AU.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('BE')"><img title="Belgique" class="country"
                  [src]="'assets/country/BE.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('BR')"><img title="Brésil" class="country"
                  [src]="'assets/country/BR.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('CA')"><img title="Canada" class="country"
                  [src]="'assets/country/CA.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('CH')"><img title="Chili" class="country"
                  [src]="'assets/country/CH.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('CN')"><img title="Chine" class="country"
                  [src]="'assets/country/CN.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('CL')"><img title="Colombie" class="country"
                  [src]="'assets/country/CL.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('KR')"><img title="Corée du Sud" class="country"
                  [src]="'assets/country/KR.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('AE')"><img title="Emirats Arabes Unis" class="country"
                  [src]="'assets/country/AE.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('ES')"><img title="Espagne" class="country"
                  [src]="'assets/country/SP.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('US')"><img title="Etats-Unis" class="country"
                  [src]="'assets/country/US.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('FJ')"><img title="Fiji" class="country"
                  [src]="'assets/country/FJ.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('FR')"><img title="France" class="country"
                  [src]="'assets/country/FR.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('GE')"><img title="Géorige" class="country"
                  [src]="'assets/country/GE.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('HK')"><img title="Hong Kong" class="country"
                  [src]="'assets/country/HK.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('IN')"><img title="Inde" class="country"
                  [src]="'assets/country/IN.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('JP')"><img title="Japon" class="country"
                  [src]="'assets/country/JP.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('PY')"><img title="Paraguay" class="country"
                  [src]="'assets/country/PY.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('PH')"><img title="Philippines" class="country"
                  [src]="'assets/country/PH.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('PL')"><img title="Pologne" class="country"
                  [src]="'assets/country/PL.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('PR')"><img title="Portugal" class="country"
                  [src]="'assets/country/PR.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('CZ')"><img title="République Tchèque" class="country"
                  [src]="'assets/country/CZ.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('RO')"><img title="Roumanie" class="country"
                  [src]="'assets/country/RO.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('GB')"><img title="Royaume-Uni" class="country"
                  [src]="'assets/country/EN.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('SE')"><img title="Suède" class="country"
                  [src]="'assets/country/SE.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('TH')"><img title="Thaïlande" class="country"
                  [src]="'assets/country/TH.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('TR')"><img title="Turquie" class="country"
                  [src]="'assets/country/TR.png'">&nbsp;</span>
              <span *ngIf="list.country.includes('UY')"><img title="Uruguay" class="country"
                  [src]="'assets/country/UY.png'">&nbsp;</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="imdbRating">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle">
              <span *ngIf="showImdb">Note Imdb</span></th>
            <td mat-cell *matCellDef="let list">
              <span *ngIf="showImdb">
                <span *ngIf="list.rating > 6 && list.rating > 0" class="displayImdbRatingTop">
                  {{list.rating}}
                </span>
                <span *ngIf="list.rating < 4 && list.rating > 0" class="displayImdbRatingFlop">
                  {{list.rating}}
                </span>
                <span *ngIf="list.rating < 6 && list.rating > 4" class="displayImdbRating">
                  {{list.rating}}
                </span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="seeOnNetflix">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Aller sur NETFLIX </th>
            <td mat-cell *matCellDef="let list">
              <span *ngIf="list.netflixId">
                <a href="https://www.netflix.com/title/{{list.netflixId}}" target="_blank">
                  GO !
                </a>
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay;"></tr>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
      </mat-card>



    </div>
  </div>
</div>