import { AuthState } from './auth.models';
import { AuthActions, AuthActionTypes } from './auth.actions';

export const initialState: AuthState = {
  isAuthenticated: false,
  userId: '',
  userName: ''
};

export function authReducer(
  state: AuthState = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        isAuthenticated: action.userId != '215',
        userId: action.userId,
        userName: action.userName
      };

    case AuthActionTypes.LOGOUT:
      return { ...state, isAuthenticated: false, userId: '', userName: '' };

    default:
      return state;
  }
}
