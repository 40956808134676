import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lamentaliste-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
  friends : Object[];
  friendsToValidate : Object[];
  friendsWaitingVal : Object[];
  friendToAdd: string = '';
  storeSubscription: Subscription;
  isVisitor: boolean = true;

  constructor(
    private data: DataService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.loadLists();
    if( this.auth.getUserInfos()[0] != '215' ){
      this.isVisitor = false;
    }
  }

  private loadLists(){
    this.getFriends();
    this.getFriendsToValidate();
    this.getFriendsWaitingVal();

  }

  private getFriends(){
    this.data.getFreinds().subscribe(rep => {
      console.log(rep);
      this.friends = Object.assign([], rep);
    });
  }

  private getFriendsToValidate(){
    this.data.getFriendsToValidate().subscribe(rep => {
      console.log(rep);
      this.friendsToValidate = Object.assign([], rep);
    });
  }

  private getFriendsWaitingVal(){
    this.data.getFriendsWaitingVal().subscribe(rep => {
      console.log(rep);
      this.friendsWaitingVal = Object.assign([], rep);
    });
  }

  addFriend(){
    if( this.friendToAdd.toUpperCase() === this.auth.getUserInfos()[1].toUpperCase() ){
      alert("C'est votre propre pseudo !");
    } else {
      this.data.getUserByPseudo(this.friendToAdd).subscribe(rep => {
        console.log(rep);
        if( rep[0] === undefined ){
          alert("Ce pseudo n'existe pas" );
        } else {
          this.data.insertFriend(rep[0].uid).subscribe(rep => {
            console.log(rep);
            this.loadLists();
          });
        }
      });
    }
  }

  removeFreindship(friendId: string){
    if(confirm("Etes-vous sûr de vouloir retirer de la liste d'amis ?")) {
      this.data.removeFriendship(friendId).subscribe(res => {
        console.log(res); 
        this.loadLists();
      })
    }
  }

  updateFriendship(ami1: string, ami2:string, accept: string){
    this.data.updateFriendship(ami1, ami2, accept).subscribe(res => {
      console.log(res); 
      this.loadLists();
    })    
  }

}
