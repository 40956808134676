<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="generalShare">
  &nbsp;
  <button class="menuIcon" (click)="onClose()" mat-mini-fab color="primary">
    <mat-icon>close</mat-icon>
  </button>
  &nbsp;&nbsp;
  <mat-form-field>
    <mat-label>
      {{ 'lamentaliste.share.selectFriend' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedFriend">
      <mat-option *ngFor="let friend of friendsNotShared" [value]="friend.uid">
        {{friend.pseudo}}
      </mat-option>
    </mat-select>
  </mat-form-field>&nbsp;&nbsp;
  &nbsp;&nbsp;

  <button mat-raised-button color="accent" (click)="shareWithFriend(selectedFriend)">
    {{ 'lamentaliste.label.add' | translate}}
  </button>
  <div>
  </div>
  <!-- display list of friends -->
  <div><b>{{ 'lamentaliste.share.sharedWith' | translate }}</b><br>
    <div *ngFor="let friend of friendsSharedWith">
      <button title="{{ 'lamentaliste.share.removeSharing' | translate }}" mat-button class="menuIcon"
        (click)="deleteShareFriend(friend.idami)" color="primary">
        <mat-icon>cancel</mat-icon>
      </button>
      {{friend.pseudo}}
    </div>
    <div>
      <div><b>{{ 'lamentaliste.share.typeOfSharing' | translate }} : </b>
        <span *ngIf="public == '0'">{{ 'lamentaliste.share.isPrivate' | translate }}</span>
        <span *ngIf="public == '1'">{{ 'lamentaliste.share.isPublic' | translate }}</span>
        <span *ngIf="public == '2'">{{ 'lamentaliste.share.isCollaborative' | translate }}</span>
        <span *ngIf="public == '3'">{{ 'lamentaliste.share.isCollaborativeForAll' | translate }}</span>
        <br>
        <div *ngIf="public == '0'"><button mat-button class="menuIcon" (click)="makeListPublic('1')" color="primary">
            <mat-icon>group</mat-icon>
          </button> {{ 'lamentaliste.share.public' | translate }}</div>
        <div *ngIf="public != '0'"><button mat-button class="menuIcon" (click)="makeListPublic('0')" color="primary">
            <mat-icon>people_outline</mat-icon>
          </button> {{ 'lamentaliste.share.private' | translate }}</div>
        <div *ngIf="public != '2'"><button mat-button class="menuIcon" (click)="makeListPublic('2')" color="primary">
            <mat-icon>group_add</mat-icon>
          </button> {{ 'lamentaliste.share.privateCollaborative' | translate }}</div>
        <div *ngIf="public != '3'"><button mat-button class="menuIcon" (click)="makeListPublic('3')" color="primary">
            <mat-icon>public</mat-icon>
          </button> {{ 'lamentaliste.share.publicCollaborative' | translate }}</div>

      </div>