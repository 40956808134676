<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel">
      <h1>{{ 'lamentaliste.sixdegrees.title' | translate }}</h1>
      {{'lamentaliste.sixdegrees.rules' | translate }}
      <lamentaliste-search *ngIf="!nextIsPerson" [isQuiz]="true" [showAddMovie]="true" [showAddTV]="false"
        [showAddPerson]="false" (uploaded)="addItem($event)"></lamentaliste-search>
      <lamentaliste-search *ngIf="nextIsPerson" [isQuiz]="true" [showAddMovie]="false" [showAddTV]="false"
        [showAddPerson]="true" (uploaded)="addItem($event)"></lamentaliste-search>
      <br>
      Trouvez le lien entre
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <lamentaliste-sixitem [personid]="personOneId"></lamentaliste-sixitem>
        &nbsp;et&nbsp;&nbsp;
        <lamentaliste-sixitem [personid]="personObjectiveId"></lamentaliste-sixitem>
      </div>
      <div *ngIf="showSuccess">
        {{'lamentaliste.sixdegrees.success' | translate }}
        {{'lamentaliste.sixdegrees.successScore' | translate }}{{(steps.length) / 3}}
      </div>
      <button class="menuIcon" (click)="onBackspace()" mat-mini-fab color="primary">
        <mat-icon>backspace</mat-icon>
      </button>
      <br>
      <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
        <div *ngFor="let step of steps" fxFlex="0 1 calc(30% - 32px)" class="content">
          <div *ngIf="step.movie != null" class="itemMovie">
            Dans
            <lamentaliste-sixitem [movieid]="step.movie"></lamentaliste-sixitem>
          </div>
          <div *ngIf="step.movie == null" class="itemPerson">
            Avec
            <lamentaliste-sixitem [personid]="step.person"></lamentaliste-sixitem>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>