import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lamentaliste-detail-person',
  templateUrl: './detail-person.component.html',
  styleUrls: ['./detail-person.component.scss']
})
export class DetailPersonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
