import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { DataService } from '../data.service';

@Component({
  selector: 'lamentaliste-quizfront',
  templateUrl: './quizfront.component.html',
  styleUrls: ['./quizfront.component.scss']
})
export class QuizfrontComponent implements OnInit {
  quizList: Object[] = [];
  public lastQuiz : Object;
  public classementQuiz : Object[];
  public nbClassementQuizShow : string; 

  columnsToDisplay = ['date', 'image', 'title', 'score', 'averageScore', 'nbPlayers', 'standing'];
  dataSource = new MatTableDataSource<Object>(this.quizList);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private data: DataService
  ) { }

  ngOnInit() {
    this.getQuizList();
  }

  getQuizList(){
    this.data.getQuizList().subscribe(rep => {    
      this.quizList = Object.assign([], rep);
      this.dataSource = new MatTableDataSource<Object>(this.quizList);
      this.dataSource.paginator = this.paginator;
      this.lastQuiz = this.quizList[0];
    });
  }

  public openQuiz(id: string){
    window.location.href = '#/quiz?id=' + id;
  }

  public showClassementQuiz(game : string){
    this.data.getQuizTable(game).subscribe(rep => {
      this.classementQuiz = Object.assign([], rep);
      if( this.nbClassementQuizShow == game ){
        this.nbClassementQuizShow = "";
      } else {
        this.nbClassementQuizShow = game;
      }
    });
  }

}
