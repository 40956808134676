<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel">
      <h1>{{ 'lamentaliste.title.myAccount' | translate }}</h1>
      <form (ngSubmit)="modifyAccount()" autocomplete="off">
        <div class="formFields">
          <br><br>
          <mat-form-field class="mat-input-placeholder">
            <input tpye="email" [(ngModel)]="userEmail" name="userEmail" autocomplete="off" matInput
              placeholder="{{ 'lamentaliste.label.email' | translate}}">
          </mat-form-field>
          <br><br>

          <button mat-stroked-button (click)="onClickShowAvatar(); $event.preventDefault()">
              {{ 'lamentaliste.myaccount.avatar' | translate}}
          </button>
          <br>
          <div *ngIf="selectedAvatar">
            <img class="avatarImg" [src]="avatars[selectedAvatar].viewValue">
          </div>
          <div *ngIf="userAvatar && selectedAvatar == null">
            <img class="avatarImg" [src]="userAvatar">
          </div>
          <div *ngIf="showAvatars">
          <span *ngFor="let avatarImg of avatars;  index as i">
              <img *ngIf="userAvatar == avatarImg.viewValue" class="avatarImgSel" [src]="avatarImg.viewValue">
              <div *ngIf="i == nbAvatarsMale"><br><br></div>
              <img (click)="selectAvatar(i)" *ngIf="userAvatar != avatarImg.viewValue" class="avatarImg" [src]="avatarImg.viewValue">
          </span>
        </div>

          <br><br>
          <mat-form-field class="mat-input-placeholder">
            <input [(ngModel)]="userPassword" name="userPassword" type="password" matInput autocomplete="off"
              placeholder="{{ 'lamentaliste.label.passwordactual' | translate}}">
          </mat-form-field>
          <br><br>
          <button mat-stroked-button *ngIf="!isModifyPassword"
            (click)="updateModifyPassword(true)">{{ 'lamentaliste.myaccount.modifyPassword' | translate }}</button>
          <button mat-stroked-button *ngIf="isModifyPassword"
            (click)="updateModifyPassword(false)">{{ 'lamentaliste.myaccount.hideModifyPassword' | translate }}</button>
          <br><br>
          <div *ngIf="isModifyPassword">
            <mat-form-field class="mat-input-placeholder">
              <input [(ngModel)]="userPasswordModify" name="userPasswordModify" type="password" matInput
                autocomplete="off" placeholder="{{ 'lamentaliste.label.passwordmodify' | translate}}">
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field class="mat-input-placeholder">
              <input [(ngModel)]="userPasswordConfirm" name="userPasswordConfirm" type="password" matInput
                autocomplete="off" placeholder="{{ 'lamentaliste.label.passwordconfirm' | translate}}">
            </mat-form-field>
            <br><br>
          </div>
          <button type="submit" mat-raised-button color="accent">
            {{ 'lamentaliste.label.validate' | translate}}
          </button>
          <br><br>
        </div>
      </form>
    </div>
  </div>
</div>