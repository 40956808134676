<p>
  <span title="{{name}}" *ngIf="name.length <= 12">
    {{name.replace("''", "'")}}</span>
  <span title="{{name}}" *ngIf="name.length > 12">
    {{name.substr(0,12).replace("''", "'")}}...</span>
  <br>
  <div *ngIf="personid && !quizId"><img src="{{image}}"></div>
  <div *ngIf="quizId">
    <a href="#/quiz?id={{quizId}}"><img src="{{image}}"></a>
  </div>
  <div *ngIf="movieid && !quizId">
      <a href="#/detailMovie?id={{movieid}}&category=1" target="new"><img src="{{image}}"></a>
  </div>
  