import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'lamentaliste-sixitem',
  templateUrl: './sixitem.component.html',
  styleUrls: ['./sixitem.component.scss']
})
export class SixitemComponent implements OnInit {
  @Input() personid: string;
  @Input() movieid: string;
  @Input() quizId: string;

  public name: string = "";
  public image : string = "";

  constructor(    
    private data: DataService
    ) { }

  ngOnInit() {
    if( this.personid != null ){
      this.loadPerson();
    } else {
      this.loadMovie();
    }
  } 

  private loadPerson(){
    this.data.getPersonById(this.personid).subscribe(rep => {
      this.image = "https://image.tmdb.org/t/p/w92/" + rep["profile_path"];
      this.name = rep["name"];
      console.log(rep);
    });
  }

  private loadMovie(){
    this.data.getMovieInfo(this.movieid).subscribe(rep => {
      this.image = "https://image.tmdb.org/t/p/w92/" + rep["poster_path"];
      this.name = rep["title"];
      console.log(rep);
    });
  }
}
