<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="background">
  <div class="gradient">
    <div *ngIf="authInfos && items" class="container backgroundPanel">
      <h1>{{authInfos.listName}}</h1>
      <div *ngIf="showRename">
          <form (ngSubmit)="renameList()">
              <mat-form-field>
                <input [(ngModel)]="newListName" name="newListName" type="text" matInput
                  placeholder=" {{ 'lamentaliste.content.renameListButton' | translate }} ">
              </mat-form-field>&nbsp;&nbsp;
              <button mat-raised-button color="accent" *ngIf="newListName.length > 0">
                {{ 'lamentaliste.content.renameListButton' | translate}}
              </button>
          </form>  
      </div>
      <div class="by"><span *ngIf="authLevel != '2'">{{ 'lamentaliste.by' | translate }}{{authInfos.ownerName}}</span>
        ({{items.length}} {{ 'lamentaliste.element' | translate }}<span *ngIf="items.length > 1">s</span>)</div>
      <div class="shareType" *ngIf="cptLikes > 1">
          {{cptLikes}}{{ 'lamentaliste.like.personnes' | translate }}
      </div>
      <div class="shareType" *ngIf="cptLikes == 1">
          {{ 'lamentaliste.like.personne' | translate }}
      </div>
      <div class="shareType" *ngIf="authInfos.public == '0'">{{ 'lamentaliste.share.isPrivate' | translate }}</div>
      <div class="shareType" *ngIf="authInfos.public == '1'">{{ 'lamentaliste.share.isPublic' | translate }}</div>
      <div class="shareType" *ngIf="authInfos.public == '2'">{{ 'lamentaliste.share.isCollaborative' | translate }}
      </div>
      <div class="shareType" *ngIf="authInfos.public == '3'">
        {{ 'lamentaliste.share.isCollaborativeForAll' | translate }}</div>

      <button *ngIf="authLevel != '1' && !like && authInfos.connectedUser != '215' &&
      authInfos.connectedUser != authInfos.owner" title="{{ 'lamentaliste.like' | translate }}" class="menuIcon"
        (click)="likeList(1)" mat-mini-fab color="primary">
        <mat-icon>favorite_border</mat-icon>
      </button>
      <button *ngIf="authLevel != '1' && like && authInfos.connectedUser != '215' &&
      authInfos.connectedUser != authInfos.owner" title="{{ 'lamentaliste.dislike' | translate }}" class="menuIcon"
        (click)="likeList(0)" mat-mini-fab color="primary">
        <mat-icon>favorite</mat-icon>
      </button>
      <div>
        <button mat-button [matMenuTriggerFor]="menu" class="menu">Menu</button>
        <mat-menu #menu="matMenu">
          <button *ngIf="authLevel === '1' || authLevel === '2'" mat-menu-item
            (click)="onAddMovieButton()">{{ 'lamentaliste.addMovie' | translate }}</button>
          <button *ngIf="authLevel === '1' || authLevel === '2'" mat-menu-item
            (click)="onAddTVButton()">{{ 'lamentaliste.addSerie' | translate }}</button>
          <button *ngIf="authLevel === '2'" mat-menu-item
            (click)="onShareListButton()">{{ 'lamentaliste.shareList' | translate }}</button>
            <button *ngIf="authLevel === '2'" (click)="showRename = !showRename"
            mat-menu-item>{{ 'lamentaliste.content.menu.renameList' | translate }}</button>
            <button *ngIf="authLevel === '2'" (click)="deleteList()" class="redBackground"
          mat-menu-item>{{ 'lamentaliste.content.menu.deleteList' | translate }}</button>
        </mat-menu>
        <span *ngIf="authLevel === '2'"> {{ 'lamentaliste.ownerMode' | translate }}</span>
        <span *ngIf="authLevel === '1'"> {{ 'lamentaliste.editionMode' | translate }}</span>
        <span *ngIf="authLevel === '0'"> {{ 'lamentaliste.viewOnlyMode' | translate }}</span>
        <button *ngIf="(authLevel === '1' || authLevel === '2') && !showRanking"
          title="{{ 'lamentaliste.showRanking' | translate }}" class="menuIcon" (click)="onShowRanking()" mat-mini-fab
          color="primary">
          <mat-icon>format_list_numbered</mat-icon>
        </button>
        <button *ngIf="(authLevel === '1' || authLevel === '2') && showRanking"
          title="{{ 'lamentaliste.showAlpha' | translate }}" class="menuIcon" (click)="onShowRanking()" mat-mini-fab
          color="primary">
          <mat-icon>sort_by_alpha</mat-icon>
        </button>
        <button *ngIf="authLevel === '1' || authLevel === '2'" title="{{ 'lamentaliste.addSerie' | translate }}"
          class="menuIcon" (click)="onAddTVButton()" mat-mini-fab color="primary">
          <mat-icon>tv</mat-icon>
        </button>
        <button *ngIf="authLevel === '1' || authLevel === '2'" title="{{ 'lamentaliste.addMovie' | translate }}"
          class="menuIcon" (click)="onAddMovieButton()" mat-mini-fab color="primary">
          <mat-icon>movie</mat-icon>
        </button>
        <button *ngIf="authLevel === '2' && authInfos.owner != '215'" title="{{ 'lamentaliste.renameList' | translate }}"
          class="menuIcon" (click)="showRename = !showRename" mat-mini-fab color="primary">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="authLevel === '2' && authInfos.owner != '215'" title="{{ 'lamentaliste.shareList' | translate }}"
          class="menuIcon" (click)="onShareListButton()" mat-mini-fab color="primary">
          <mat-icon>share</mat-icon>
        </button>
      </div>
      <br>
      <lamentaliste-search *ngIf="showAddMovie || showAddTV" [showAddMovie]="showAddMovie" [showAddTV]="showAddTV"
        [listId]="listId" [items]="items" (uploaded)="addItem($event)" (uploadedClose)="closeAddZone()">
      </lamentaliste-search>
      <lamentaliste-share-list *ngIf="showShareZone" [listId]="listId" [public]="authInfos.public"
        (uploadedCloseShare)="closeShareZone()" (uploadedPublicStatus)="updatePublicStatus()">
      </lamentaliste-share-list>
      <button class="center" *ngIf="showRanking" mat-button class="menu" (click)="saveRanking()">Enregistrer
        classement</button>
        &nbsp;<button *ngIf="authLevel === '2' && authInfos.owner != '215' && showRanking" title="{{ 'lamentaliste.showImage' | translate }}"
          class="menuIcon" (click)="onShowImage()" mat-mini-fab color="primary">
          <mat-icon>image</mat-icon>
        </button>
        <br><br>
      <div *ngIf="showRanking" fxLayout="row wrap" fxLayoutGap="20px" class="displayListRank">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <!--[cdkDragDisabled]="true"-->
          <div class="example-box" *ngFor="let item of items;  index as i" cdkDrag
            [cdkDragDisabled]="authLevel != '1' && authLevel != '2'">
            <span>{{i+1}}</span>&nbsp;
            <a *ngIf="showImage" href="#/detailMovie?id={{item.image}}&listId={{listId}}&category={{item.categorie}}"><img
                class="cardimgSmall" title="{{item.Titre}}" src="assets/posters/{{item.image}}.jpg"></a>
            {{item.Titre}}
            <img *cdkDragPreview src="assets/posters/{{item.image}}.jpg" [alt]="item.image">
            <button *ngIf="authLevel === '2' || authLevel === '1'" mat-button class="delRanking"
              (click)="delClicked(item)">
              <mat-icon>delete</mat-icon></button>
          </div>
        </div>
      </div>

      <div *ngIf="!showRanking" fxLayout="row wrap" fxLayoutGap="20px" class="displayList">
        <ng-container *ngFor="let item of items">
          <div id="{{item.image}}" class="displayList" fxFlex="0 1 calc(20% - 32px)">
            <mat-card class="mat-card">
              <lamentaliste-list-item [item]="item" [listId]="listId"></lamentaliste-list-item>
              <span class="addedBy" *ngIf="item.pseudo && (authInfos.public == '2' || authInfos.public == '3')">
                  {{ 'lamentaliste.addedBy' | translate }}
                  <span *ngIf="item.avatar">
                      <img class="avatarMini" [src]="item.avatar">
                  </span>    
                  {{item.pseudo}}
              </span>
              <mat-card-actions *ngIf="authLevel === '2' || authLevel === '1'">
                <!--<button title="{{ 'lamentaliste.delete' | translate }}" mat-mini-fab color="primary" (click)="delClicked(item)"><mat-icon>clear</mat-icon></button>-->
                <button mat-button (click)="delClicked(item)">{{ 'lamentaliste.delete' | translate }}</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>