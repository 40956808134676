import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SettingsContainerComponent } from './settings';
import { HomeComponent } from '@app/home/home.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { LoginComponent } from './login/login.component';
import { ListContentComponent } from './list-content/list-content.component';
import { DetailMovieComponent } from './detail-movie/detail-movie.component';
import { DetailPersonComponent } from './detail-person/detail-person.component';
import { FriendsComponent } from './friends/friends.component';
import { SixdegreesComponent } from './sixdegrees/sixdegrees.component';
import { QuizComponent } from './quiz/quiz.component';
import { ExtractComponent } from './extract/extract.component';
import { QuizmenuComponent } from './quizmenu/quizmenu.component';
import { QuizfrontComponent } from './quizfront/quizfront.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { NetflixComponent } from './netflix/netflix.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'netflix',
    component: NetflixComponent,
    pathMatch: 'full'
  },
  {
    path: 'myaccount',
    component: MyAccountComponent,
    pathMatch: 'full'
  },
  {
    path: 'friends',
    component: FriendsComponent,
    pathMatch: 'full'
  },
  {
    path: 'quizfront',
    component: QuizfrontComponent,
    pathMatch: 'full'
  },
  {
    path: 'quizmenu',
    component: QuizmenuComponent,
    pathMatch: 'full'
  },
  {
    path: 'sixdegrees',
    component: SixdegreesComponent,
    pathMatch: 'full'
  },
  {
    path: 'quiz',
    component: QuizComponent,
    pathMatch: 'full'
  },
  {
    path: 'extract',
    component: ExtractComponent,
    pathMatch: 'full'
  },
  {
    path: 'createAccount',
    component: CreateAccountComponent,
    pathMatch: 'full'
  },
  {
    path: 'listContent',
    component: ListContentComponent,
    pathMatch: 'full'
  },
  {
    path: 'detailMovie',
    component: DetailMovieComponent,
    pathMatch: 'full'
  },
  {
    path: 'detailPerson',
    component: DetailPersonComponent,
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'about',
    redirectTo: 'about',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    component: SettingsContainerComponent,
    data: { title: 'lamentaliste.menu.settings' }
  },
  {
    path: 'examples',
    loadChildren: () =>
      import('app/examples/examples.module').then(m => m.ExamplesModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64] // [x, y]
    ,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
