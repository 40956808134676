import browser from 'browser-detect';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataService } from './data.service';

import {
  ActionAuthLogin,
  AnimationsService,
  TitleService,
  selectAuth,
  routeAnimations,
  AppState
} from '@app/core';
import { environment as env } from '@env/environment';

import {
  NIGHT_MODE_THEME,
  selectSettings,
  SettingsState,
  ActionSettingsPersist,
  ActionSettingsChangeLanguage,
  ActionSettingsChangeAnimationsPageDisabled
} from './settings';

@Component({
  selector: 'lamentaliste-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  @HostBinding('class') componentCssClass;

  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  api_url = 'http://localhost:4200/lamentaliste/api/v1';
  visitorId = 214;
  adminId = 215;
  year = new Date().getFullYear();
  logo = require('../assets/logo.png');
  languages = ['en', 'fr'];
  navigation = [
    { link: 'home', label: 'lamentaliste.menu.home' },
    { link: 'friends', label: 'lamentaliste.menu.friends' },
    { link: 'quizfront', label: 'lamentaliste.menu.quiz' },
    { link: 'quizmenu', label: 'lamentaliste.menu.games' },
    { link: 'netflix', label: 'lamentaliste.menu.netflix' }
  ];
  navigationSideMenu = [
    ...this.navigation,
    { link: 'settings', label: 'lamentaliste.menu.settings' }
  ];

  settings: SettingsState;
  isAuthenticated: boolean;
  isHeaderSticky: boolean;
  userId: string;
  userName: string;
  userAvatar: string;

  constructor(
    public overlayContainer: OverlayContainer,
    private store: Store<AppState>,
    private router: Router,
    private titleService: TitleService,
    private animationService: AnimationsService,
    private translate: TranslateService,
    private dataService: DataService
  ) {
    if (this.isProd) {
      this.api_url = '../api/v1';
      this.visitorId = 215;
      this.adminId = 216;
    }
  }

  private static trackPageView(event: NavigationEnd) {
    (<any>window).ga('set', 'page', event.urlAfterRedirects);
    (<any>window).ga('send', 'pageview');
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    this.translate.setDefaultLang('fr');
    this.subscribeToSettings();
    this.subscribeToIsAuthenticated();
    this.subscribeToRouterEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCreateAccountClick() {}

  onLoginClick() {
    //let test = this.dataService.getInfo();
    //this.store.dispatch(new ActionAuthLogin());
  }

  onLogoutClick() {
    this.store.dispatch(new ActionAuthLogin('215', 'Visiteur'));
    window.location.href = '#/home';
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  private subscribeToIsAuthenticated() {
    this.store
      .pipe(select(selectAuth), takeUntil(this.unsubscribe$))
      .subscribe(auth => {
        if( auth.isAuthenticated ){
          this.isAuthenticated = auth.isAuthenticated;
          this.userName = auth.userName;
          this.dataService.getAllUserInfos().subscribe(rep=> {
            let user : Object = Object.assign([], rep);  
            if( user[0].avatar != null && user[0].avatar != '' ){
              this.userAvatar = user[0].avatar;
            } else {
              this.userAvatar = null;
            }
       });
        } else {
          this.isAuthenticated = false;
          this.userName = 'Visiteur';
          this.userAvatar = null;
        }
      });
  }

  private subscribeToSettings() {
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.store
      .pipe(select(selectSettings), takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.settings = settings;
        this.setTheme(settings);
        this.setStickyHeader(settings);
        this.setLanguage(settings);
        this.animationService.updateRouteAnimationType(
          settings.pageAnimations,
          settings.elementsAnimations
        );
      });
  }

  private setTheme(settings: SettingsState) {
    const { theme, autoNightMode } = settings;
    const hours = new Date().getHours();
    const effectiveTheme = (autoNightMode && (hours >= 20 || hours <= 6)
      ? NIGHT_MODE_THEME
      : theme
    ).toLowerCase();
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) =>
      item.includes('-theme')
    );
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  private setStickyHeader(settings: SettingsState) {
    this.isHeaderSticky = settings.stickyHeader;
  }

  private setLanguage(settings: SettingsState) {
    const { language } = settings;
    if (language) {
      this.translate.use(language);
    }
  }

  private subscribeToRouterEvents() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.titleService.setTitle(event.snapshot);
      }

      if (event instanceof NavigationEnd) {
        AppComponent.trackPageView(event);
      }
    });
  }
}
