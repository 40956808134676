import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';

import { SettingsModule } from './settings';
import { StaticModule } from './static';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { LoginComponent } from './login/login.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { ListContentComponent } from './list-content/list-content.component';
import { ListItemDirective } from './list-item.directive';
import { ListItemComponent } from './list-item/list-item.component';
import { DetailMovieComponent } from './detail-movie/detail-movie.component';
import { DetailPersonComponent } from './detail-person/detail-person.component';
import { SearchComponent } from './search/search.component';
import { FriendsComponent } from './friends/friends.component';
import { ShareListComponent } from './share-list/share-list.component';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SixdegreesComponent } from './sixdegrees/sixdegrees.component';
import { QuizmenuComponent } from './quizmenu/quizmenu.component';
import { QuizfrontComponent } from './quizfront/quizfront.component';
import { SixitemComponent } from './sixitem/sixitem.component';
import { QuizComponent } from './quiz/quiz.component';
import { ExtractComponent } from './extract/extract.component';
import { MyAccountComponent } from './my-account/my-account.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NetflixComponent } from './netflix/netflix.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

//const globalSettings: RecaptchaSettings = { siteKey: '6LfhsrEUAAAAAGFuqrmELu-WykN1jo3X6ZakWFQ9' };
const globalSettings: RecaptchaSettings = {
  siteKey: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU'
};

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    RecaptchaModule,
    // core & shared
    CoreModule,
    SharedModule,
    // features
    StaticModule,
    SettingsModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatRadioModule,
    DragDropModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonToggleModule,
    // app
    AppRoutingModule,
    // http
    HttpClientModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    CreateAccountComponent,
    LoginComponent,
    ListContentComponent,
    ListItemDirective,
    ListItemComponent,
    DetailMovieComponent,
    DetailPersonComponent,
    SearchComponent,
    FriendsComponent,
    ShareListComponent,
    SixdegreesComponent,
    QuizmenuComponent,
    SixitemComponent,
    QuizComponent,
    ExtractComponent,
    MyAccountComponent,
    QuizfrontComponent,
    NetflixComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
