<div class="background">
    <div class="gradient">
        <div class="container backgroundPanel">
            <h1>{{ 'lamentaliste.games.title' | translate }}</h1>
            <mat-card>
            <div fxFlex="0 1 calc(90% - 32px)" class="homeHeaderParts">
                <div>
   
                <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Date </th>
                        <td mat-cell *matCellDef="let list" class="displayContent"> {{list.pdate  | date:'dd-MM-yyyy'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Titre </th>
                        <td mat-cell *matCellDef="let list"> 
                          <span class="displayList2" (click)="openQuiz(list.id)">{{list.title}}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let list"> 
                            <img *ngIf="list.image" (click)="openQuiz(list.id)" class="cardimg" title="{{list.title}}" src="assets/posters/{{list.image}}.jpg">
                    </ng-container>
                    <ng-container matColumnDef="score">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'lamentaliste.table.quiz.you' | translate }} </th>
                        <td mat-cell *matCellDef="let list" class="tabCenteredGreen displayContent"> {{list.score}} </td>
                    </ng-container>
                    <ng-container matColumnDef="averageScore">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'lamentaliste.table.quiz.averageScore' | translate }} </th>
                        <td mat-cell *matCellDef="let list" class="tabCentered displayContent"> {{list.averageScore}} </td>
                    </ng-container>
                    <ng-container matColumnDef="nbPlayers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'lamentaliste.table.quiz.player' | translate }} </th>
                        <td mat-cell *matCellDef="let list" class="tabCentered displayContent"> {{list.nbPlayers}} </td>
                    </ng-container>
                    <ng-container  matColumnDef="standing">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let list" class="tabCentered">
                            <button mat-button *ngIf="list.nbPlayers > 0"
                                (click)="showClassementQuiz(list.id)">{{ 'lamentaliste.sixdegrees.table' | translate }}</button>
                            
                            <div class="classement" *ngIf="nbClassementQuizShow == list.id">
                                <div *ngFor="let cl of classementQuiz">
                                   {{cl.pseudo}} : {{cl.score}} pts
                                </div>
                              </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay displayContent"></tr>
                    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
              </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
           </div>
        </mat-card>
        </div>
    </div>
</div>