<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel">
      <h1>{{ 'lamentaliste.games.quiz.title' | translate }}</h1>
      {{'lamentaliste.games.quiz.description' | translate }}
      <br>
      <div *ngIf="quiz">
        <span class="quizTitle">{{quiz.title}}<br></span>
        <br>
        <div *ngIf="finalScore" class="correct">
          {{'lamentaliste.games.quiz.displayScore' | translate }}{{finalScore}}
        </div>

        <div *ngFor="let question of quiz.qList; let i = index">
          <div class="questionBackground">
          <span class="question">Question {{i + 1}}<br>
            {{quiz.qList[i].question}}</span><br>
          </div>
          <div>
            <mat-radio-group [(ngModel)]="quiz.qList[i].rep">
            <mat-radio-button value="1">{{quiz.qList[i].prop1}}</mat-radio-button><br>
            <mat-radio-button value="2">{{quiz.qList[i].prop2}}</mat-radio-button><br>
            <mat-radio-button value="3">{{quiz.qList[i].prop3}}</mat-radio-button><br>
          </mat-radio-group>
          <br>
          <div class="fail" *ngIf="finalScore && quiz.qList[i].rep != quiz.qList[i].answer">
            {{'lamentaliste.games.quiz.goodAnswer' | translate }}
            <span *ngIf="quiz.qList[i].answer == 1">{{quiz.qList[i].prop1}}</span>
            <span *ngIf="quiz.qList[i].answer == 2">{{quiz.qList[i].prop2}}</span>
            <span *ngIf="quiz.qList[i].answer == 3">{{quiz.qList[i].prop3}}</span>
          </div>
          <div class="correct" *ngIf="finalScore && quiz.qList[i].rep == quiz.qList[i].answer">
            {{'lamentaliste.games.quiz.correct' | translate }}
          </div>
          <br>
          </div>
        </div>
        <button mat-raised-button color="accent" (click)="validateQuiz()">
          {{ 'lamentaliste.games.quiz.validate' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>