<mat-sidenav-container>

  <mat-sidenav #sidenav mode="push">
    <div class="branding"><img [src]="logo" />
      <span data-testid="title">{{ 'lamentaliste.title.short' | translate}}</span></div>
    <mat-nav-list>
      <a mat-list-item *ngFor="let item of navigationSideMenu" (click)="sidenav.close()" [routerLink]="[item.link]"
        routerLinkActive="active">
        {{item.label | translate}}
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <div class="wrapper">

    <div class="toolbar" [style.position]="isHeaderSticky ? 'fixed' : 'inherit'" [class.mat-elevation-z4]="isHeaderSticky">
      <mat-toolbar color="primary">
        <button mat-icon-button class="d-md-none" (click)="sidenav.open()">
          <i class="fa-bars fa"></i>
        </button>

        <span routerLink="" class="branding spacer center d-inline d-sm-none">
          <img src="../assets/logo.png" /></span>
        <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none"><img src="../assets/logo.png" /> {{
          'lamentaliste.title.short' | translate }}</span>
        <span routerLink="" class="branding spacer d-none d-md-inline"><img src="../assets/logo.png" /> {{ 'lamentaliste.title' | translate
          }}</span>

        <span class="d-none d-md-inline">
          <button mat-button class="nav-button" *ngFor="let item of navigation" [routerLink]="[item.link]"
            routerLinkActive="active">
            {{item.label | translate}}
          </button>
        </span>

        <div *ngIf="!isAuthenticated" class="mat-input-placeholder">
            <button mat-button mat-stroked-button color="accent" routerLink="login">
                {{ 'lamentaliste.menu.login' | translate }}
            </button>
            <button mat-button mat-stroked-button color="accent" routerLink="createAccount">
                {{ 'lamentaliste.menu.createAccount' | translate }}
            </button>
        </div>
        <div>
          <i class="fa-user fa"></i>
          {{userName}}
        </div>
        <button *ngIf="isAuthenticated && !userAvatar"
                mat-icon-button
                [matMenuTriggerFor]="toolbarUserMenu">
                <i class="fa-user-circle fa"></i>
        </button>

        &nbsp;&nbsp;&nbsp;&nbsp;
        <button *ngIf="isAuthenticated && userAvatar"
            mat-icon-button
            [matMenuTriggerFor]="toolbarUserMenu">
            <div *ngIf="userAvatar">
              <img class="avatarImg" [src]="userAvatar">
            </div>
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;        
        <mat-menu #toolbarUserMenu="matMenu">
          <button mat-menu-item (click)="onLogoutClick()">
            <mat-icon><i class="fa-power-off fa"></i></mat-icon>
            <span>{{ 'lamentaliste.menu.logout' | translate }}</span>
          </button>
          <button mat-menu-item routerLink="myaccount">
            <mat-icon><i class="fa-user-circle fa"></i></mat-icon>
            <span>{{ 'lamentaliste.menu.myaccount' | translate }}</span>
          </button>
        </mat-menu>

        <button mat-icon-button routerLink="settings" class="d-none d-sm-inline">
          <i class="fa fa-cogs fa-2x"></i>
        </button>

        <span>
          <mat-select [ngModel]="settings?.language" (selectionChange)="onLanguageSelect($event)">
            <mat-option *ngFor="let l of languages" [value]="l">
              {{ l.toUpperCase() }}
            </mat-option>
          </mat-select>
        </span>
      </mat-toolbar>
    </div>
    <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.path">
      <router-outlet #o="outlet"></router-outlet>
    </div>

    <div class="footer">
      <div class="row">
      </div>
      <div class="row">
        <div class="col-12 signature">
          &#169; <span class="year" data-testid="footer-year">{{year}}</span> - La Mentaliste
          <br class="d-block d-sm-none">
        </div>
      </div>
    </div>

  </div>

</mat-sidenav-container>
