import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectAuth, AppState } from '@app/core';
import {Location} from '@angular/common';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DomSanitizer, SafeUrl, SafeResourceUrl, SafeStyle} from '@angular/platform-browser';
@Component({
  selector: 'lamentaliste-detail-movie',
  templateUrl: './detail-movie.component.html',
  styleUrls: ['./detail-movie.component.scss']
})
export class DetailMovieComponent implements OnInit {
  storeSubscription: Subscription;
  movieId: string;
  listId: string;
  category: string;
  movieCredits: Object[];
  movieInfos: Object[];
  movieImages: Object[];
  movieVideos: Object[];
  movieProviders: Object[];
  tvCredits: Object[];
  tvInfos: Object[];
  tvImages: Object[];
  tvVideos: Object[];
  tvProviders: Object[];
  youtube: Object[];
  externalIds: Object[];
  backgroundImage : SafeStyle;
  imdbRating: Object[];
  img: string;

  // user lists
  userId: string = localStorage.getItem('userId');
  userName: string = localStorage.getItem('userName');
  userLists: Object[];
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private data: DataService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.storeSubscription = this.store
      .pipe(select(selectAuth))
      .subscribe(auth => {
        if (auth.userId != undefined && auth.userId != '') {
          this.userId = auth.userId;
          this.userName = auth.userName;
        } else {
          this.userId = '215';
          this.userName = 'Visiteur';

        }
      });

    this.activatedRoute.queryParams.subscribe(params => {
      this.movieId = params['id'];
      this.listId = params['listId'];
      this.category = params['category'];
      console.log(this.movieId);
      if( this.category == '1'){
        this.getMovieInfos();
      } else {
        this.getTVInfos();
      }
    });
    this.getUserLists();
  }

  private getUserLists(){
    this.data.getOwnerLists(this.userId).subscribe(rep => {
      this.userLists = Object.assign([], rep);
      console.log(this.userLists);
    });
  }

  addToList(selectedList : string){
    if( this.category == '1'){
      this.data.addMovieToDB(
        this.movieInfos["title"],
        this.movieInfos["id"],
        this.movieInfos["release_date"],
        this.movieInfos["genres"],
        selectedList,
        "https://image.tmdb.org/t/p/w185_and_h278_bestv2" + this.movieInfos["poster_path"],
        Number(this.category)
      ).subscribe(rep => {
        this.snackBar.open('Transfert effectué.', null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });
      });
    } else if( this.category == '2'){
      this.data.addMovieToDB(
        this.tvInfos["name"],
        this.tvInfos["id"],
        this.tvInfos["first_air_date"],
        this.tvInfos["genres"],
        selectedList,
        "https://image.tmdb.org/t/p/w185_and_h278_bestv2" + this.tvInfos["poster_path"],
         Number(this.category)
      ).subscribe(rep => {
        this.snackBar.open('Transfert effectué.', null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });
      });
    }
  }

  private getYoutube(title: string){
    this.data.getYoutube(title).subscribe(rep => {
      this.youtube = Object.assign([], JSON.parse(rep)["items"]);
    });
  }

  private getMovieInfos(){
    this.data.getMovieInfo(this.movieId).subscribe(rep => {
      this.movieInfos = Object.assign([], rep);
      this.getYoutube(this.movieInfos["title"]);
      this.img = "http://image.tmdb.org/t/p/w500" + this.movieInfos['backdrop_path'];
      this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle('url(' + this.img + ')');
      this.data.getImdbRating(this.movieInfos['imdb_id']).subscribe(rep => {
        this.imdbRating = Object.assign([], rep);
      })
    });
    this.data.getMovieCredits(this.movieId).subscribe(rep => {
      this.movieCredits = Object.assign([], rep);
    })
    this.data.getMovieImages(this.movieId).subscribe(rep => {
      this.movieImages = Object.assign([], rep);
    })
    this.data.getMovieVideos(this.movieId).subscribe(rep => {
      this.movieVideos = Object.assign([], rep);
    })
    this.data.getMovieProviders(this.movieId).subscribe(rep => {
      this.movieProviders = Object.assign([], rep);
    })
  }

  private getTVInfos(){
    this.data.getTVInfo(this.movieId).subscribe(rep => {
      this.tvInfos = Object.assign([], rep);
      this.getYoutube(this.tvInfos["name"]);
    });
    this.data.getExternalId(this.movieId).subscribe(rep => {
      this.externalIds = Object.assign([], rep);
      this.data.getImdbRating(this.externalIds['imdb_id']).subscribe(rep => {
        this.imdbRating = Object.assign([], rep);
      })
    })
    this.data.getTVCredits(this.movieId).subscribe(rep => {
      this.tvCredits = Object.assign([], rep);
    })
    this.data.getTVImages(this.movieId).subscribe(rep => {
      this.tvImages = Object.assign([], rep);
    })
    this.data.getTVVideos(this.movieId).subscribe(rep => {
      this.tvVideos = Object.assign([], rep);
    })
    this.data.getTVProviders(this.movieId).subscribe(rep => {
      this.tvProviders = Object.assign([], rep);
    });
  }

  backClicked() {
    if( this.listId != undefined ) {
      console.log(this._location);
      this.router.navigate(["/listContent"],  { queryParams: { listId : this.listId, movieId: this.movieId }});
    } else {
      window.close();
      this._location.back();
    }
  }

  getIframeSrc(key: string) : SafeUrl {
    let videolink : string  = "https://www.youtube.com/embed/" + key;
    return this.sanitizer.bypassSecurityTrustResourceUrl(videolink);
  }

  goToLink(providerName: string) {
    let searchName : string = "";
    if( this.movieInfos != null ) {
      searchName = this.movieInfos['title'];
    } else if( this.tvInfos != null ) {
      searchName = this.tvInfos['name'];
    }

    switch(providerName) {
      case "Amazon Prime Video": { 
        window.open("https://www.primevideo.com/search/ref=atv_nb_sug?ie=UTF8&phrase=" + searchName, '_blank');
        break; 
      } 
      case "Paramount+ Amazon Channel": { 
        window.open("https://www.primevideo.com/search/ref=atv_nb_sug?ie=UTF8&phrase=" + searchName, '_blank');
        break; 
      } 
      case "Canal+": { 
        window.open("https://www.canalplus.com/", '_blank');
        break; 
      }
      case "YouTube": { 
        window.open("https://www.youtube.com/results?search_query=" + searchName, '_blank');
        break; 
      }
      case "Netflix": {
        window.open("https://www.netflix.com/search?q=" + searchName, '_blank');
        break; 
      }
      case "Netflix basic with Ads": {
        window.open("https://www.netflix.com/search?q=" + searchName, '_blank');
        break; 
      }
      case "Apple TV Plus": {
        window.open("https://tv.apple.com/", '_blank');
        break; 
      }
      case "Apple TV": {
        window.open("https://tv.apple.com", '_blank');
        break; 
      }
      case "Paramount Plus Apple TV Channel ": {
        window.open("https://tv.apple.com", '_blank');
        break; 
      }
      case "Paramount Plus": { 
        window.open("https://www.paramountplus.com/", '_blank');
        break; 
      }
      default: { 
        alert("Non géré");
        break; 
      } 
   } 
  }

}
