import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';

import { ActionAuthLogin, AppState } from '@app/core';

@Component({
  selector: 'lamentaliste-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isCaptchaWaiting: boolean = true;
  userId: string = '';
  userPassword: string = '';
  isForgottenPassword: boolean = false;
  userEmail: string = '';
  userPasswPseudo: string = '';
  constructor(
    private data: DataService,
    public snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {}

  ngOnInit(){
  }

  public userLogin() {
    this.data.userLogin(this.userId, this.userPassword).subscribe(s => {
      console.log(s);

      if (s['status'] == 'error') {
        this.snackBar.open(
          'Votre identifiant ou votre mot de passe est incorrect',
          null,
          {
            duration: 2000,
            panelClass: 'snackbarError'
          }
        );
      } else {
        this.snackBar.open('Vous êtes connecté, ' + this.userId, null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });
//        localStorage.setItem('userId', s['uid']);
//        localStorage.setItem('userName', s['pseudo']);
        window.location.href = '#/home';
        this.store.dispatch(new ActionAuthLogin(s['uid'], s['pseudo']));
      }
    });
  }

  public updateForgottenPassword(value : boolean){
    this.isForgottenPassword = value;
  }

  public sendForgottenPassword(){
    this.data.sendEmailResetPassword(this.userPasswPseudo, this.userEmail).subscribe(s => {
      if( s == 'OK' ){
        console.log('if');
      this.snackBar.open('Votre mot de passe a bien été envoyé sur votre mail.' , null, {
        duration: 2000,
        panelClass: 'snackbarOK'
      });
      } else {
        console.log('else');
        this.snackBar.open('Erreur : votre pseudo et mot de passe ne correspondent pas à un compte sur le site', null, {
          duration: 2000,
          panelClass: 'snackbarError'
        });  
      }
    });
  }

  public resolved(captchaResponse: string) {
    if( captchaResponse === null ){
      this.isCaptchaWaiting = true;
    } else {
      this.isCaptchaWaiting = false;
    }
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public isSubmitForgottenPasswDisabled() : Boolean {
    return this.isCaptchaWaiting || this.userEmail == "" || this.userPasswPseudo == "";
  }
}
