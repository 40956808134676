import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';

import { LocalStorageService } from '../local-storage/local-storage.service';

import {
  ActionAuthLogin,
  ActionAuthLogout,
  AuthActionTypes
} from './auth.actions';
 
export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  
  userId: string;
  userName: string;
  

  constructor(
    private actions$: Actions<Action>,
    private localStorageService: LocalStorageService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  @Effect({ dispatch: false })
  login = this.actions$.pipe(
    ofType<ActionAuthLogin>(AuthActionTypes.LOGIN),
    withLatestFrom(this.store, (action, state) => {
      this.userId = state.auth.userId;
      this.userName = state.auth.userName;
    }),
    tap(() =>
    {
      if( this.userId != '215'){
        this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true, userId: this.userId, userName: this.userName });
      } else {
        this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false, userId: this.userId, userName: this.userName });
      }
     })
  );

  @Effect({ dispatch: false })
  logout = this.actions$.pipe(
    ofType<ActionAuthLogout>(AuthActionTypes.LOGOUT),
    tap(() => {
      this.router.navigate(['']);
      this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false });
    })
  );
}
