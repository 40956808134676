import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Store, select } from '@ngrx/store';
import { AppState, authReducer } from '@app/core';
import { selectSettings } from './settings';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  language: string;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private auth: AuthService
  ) {

    this.store
      .pipe(select(selectSettings))
      .subscribe(settings => {
        this.language = settings.language;
      })
  }

  getData() {
    return ''; // this.http.get('');
  }

  getUserInfos(){
    return this.auth.getUserInfos()[0];
  }

  getAllUserInfos(){
    return this.http.post('/api/v1' + '/getAllUserInfos', {
      userId: this.auth.getUserInfos()[0]
    });
  }

  getLanguage(){
    return this.language;
  }

  userLogin(userId: string, userPassword: string): Observable<Object> {
    return this.http.post('/api/v1' + '/login', {
      customer: { pseudo: userId, password: userPassword }
    });
  }

  createAccount(userId: string, userPassword: string, userEmail: string): Observable<Object> {
    return this.http.post('/api/v1' + '/signup', {
      customer: { pseudo: userId, password: userPassword, email: userEmail }
    });
  }

  getOwnerLists(userId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getLists',
      { ownerId: userId }
    );
  }

  getPublicLists(userId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getPublicLists',
      { ownerId: userId }
    );
  }

  getSharedLists(userId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getSharedLists',
      { ownerId: userId }
    );
  }
/*
  getNotifiedLists(): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getNotifiedLists',
      null
    );
  }*/

  getListContent(listId: string, isranking: boolean): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getListContent',
      { ownerId: listId,
        isranking: isranking
       }
    );
  }

  getListAuthorization(listId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getListAuthorization',
      {
        userId: this.auth.getUserInfos()[0],
        listId: listId
      }
    );
  }

  //&append_to_response=images&include_image_language=en
  getMovieInfo(id: string): Observable<Object> {
    let url: string = `https://api.themoviedb.org/3/movie/${id}?api_key=3d197569c7f13f60d61a7d61d5c83427&language=${this.language}`;
    return this.http.get(url);
  }

  getMovieCredits(id: string): Observable<Object> {
    return this.http.get(
      "https://api.themoviedb.org/3/movie/" + id + "/credits?api_key=3d197569c7f13f60d61a7d61d5c83427&language=" + this.language);
  }

  getMovieImages(id: string): Observable<Object> {
    return this.http.get(
      "https://api.themoviedb.org/3/movie/" + id + "/images?api_key=3d197569c7f13f60d61a7d61d5c83427&language=" + this.language);
  }

  getMovieVideos(id: string): Observable<Object> {
    return this.http.get(
      "https://api.themoviedb.org/3/movie/" + id + "/videos?api_key=3d197569c7f13f60d61a7d61d5c83427&language=" + this.language);
  }

  getMovieProviders(id: string): Observable<Object> {
    let url: string = `https://api.themoviedb.org/3/movie/${id}/watch/providers?api_key=3d197569c7f13f60d61a7d61d5c83427`;
    return this.http.get(url);
  }

  getTVInfo(id: string): Observable<Object> {
    let url: string = `https://api.themoviedb.org/3/tv/${id}?api_key=3d197569c7f13f60d61a7d61d5c83427&language=${this.language}`;
    return this.http.get(url);
  }

  getTVCredits(id: string): Observable<Object> {
    return this.http.get(
      "https://api.themoviedb.org/3/tv/" + id + "/credits?api_key=3d197569c7f13f60d61a7d61d5c83427&language=" + this.language);
  }

  getTVImages(id: string): Observable<Object> {
    return this.http.get(
      "https://api.themoviedb.org/3/tv/" + id + "/images?api_key=3d197569c7f13f60d61a7d61d5c83427&language=" + this.language);
  }

  getTVVideos(id: string): Observable<Object> {
    return this.http.get(
      "https://api.themoviedb.org/3/tv/" + id + "/videos?api_key=3d197569c7f13f60d61a7d61d5c83427&language=" + this.language);
  }

  getTVProviders(id: string): Observable<Object> {
    let url: string = `https://api.themoviedb.org/3/tv/${id}/watch/providers?api_key=3d197569c7f13f60d61a7d61d5c83427`;
    return this.http.get(url);
  }


  getMovieFR(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/search/movie?api_key=3d197569c7f13f60d61a7d61d5c83427&query=${query}&language=FR`;
    return this.http.get(url);
  }

  getMovieEN(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/search/movie?api_key=3d197569c7f13f60d61a7d61d5c83427&query=${query}&language=EN`;
    return this.http.get(url);
  }

  getMulti(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/search/multi?api_key=3d197569c7f13f60d61a7d61d5c83427&query=${query}&language=${this.language}`;
    return this.http.get(url);
  }

  getMovie(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/search/movie?api_key=3d197569c7f13f60d61a7d61d5c83427&query=${query}&language=${this.language}`;
    return this.http.get(url);
  }

  getTV(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/search/tv?api_key=3d197569c7f13f60d61a7d61d5c83427&query=${query}&language=${this.language}`;
    return this.http.get(url);
  }

  getPerson(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/search/person?api_key=3d197569c7f13f60d61a7d61d5c83427&query=${query}&language=${this.language}`;
    return this.http.get(url);
  }

  getPersonById(query: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/person/${query}?api_key=3d197569c7f13f60d61a7d61d5c83427&language=${this.language}`;
    return this.http.get(url);
  }

  getExternalId(tmdbId: string): Observable<any> {
    let url: string = `https://api.themoviedb.org/3/tv/${tmdbId}/external_ids?api_key=3d197569c7f13f60d61a7d61d5c83427&language=${this.language}`;
    return this.http.get(url);
  }

  deleteItem(id: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/deleteItem',
      {
        id: id,
      }
    );
  }

  addMovieToDB(
    title: string,
    id: string,
    release_date: string,
    genres_ids: Array<string>,
    idlist: string,
    poster: string,
    category: number
  ): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/insertItem',
      {
        idlist: idlist,
        title: title,
        poster: poster,
        image: id,
        tmdbId: id,
        release_date: release_date,
        genres_ids: genres_ids,
        supprime: 0,
        category: category,
        userId: this.auth.getUserInfos()[0]
      }
    );
  }

  addList(listName: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/addList',
      {
        listName: listName,
        userId: this.auth.getUserInfos()[0]
      }
    );
  }

  deleteList(listId: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/deleteList',
      {
        idListe: listId
      }
    );
  }

  renameList(listId: string, newListName: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/renameList',
      {
        listid: listId,
        newListName: newListName
      }
    );
  }

  getFreinds(): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getFriends',
      {
        userId: this.auth.getUserInfos()[0]
      }
    );
  }

  getFriendsToValidate(): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getFriendsToValidate',
      {
        userId: this.auth.getUserInfos()[0]
      }
    );
  }

  getFriendsWaitingVal(): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getFriendsWaitingVal',
      {
        userId: this.auth.getUserInfos()[0]
      }
    );
  }

  getUserByPseudo(pseudo : string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getUserByPseudo',
      {
        pseudo: pseudo
      }
    );
  }

  insertFriend(friendId : string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/insertFriend',
      {
        userId : this.auth.getUserInfos()[0],
        friendId: friendId
      }
    );
  }
  

  likeList(listid : string, value: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/likeList',
      {
        userid : this.auth.getUserInfos()[0],
        listid: listid,
        value: value
      }
    );
  }
    

  removeFriendship(friendId : string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/removeFriendship',
      {
        userId : this.auth.getUserInfos()[0],
        friendId: friendId
      }
    );
  }

  updateFriendship(ami1: string, ami2: string, valid: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/updateFriendship',
      {
        ami1: ami1,
        ami2: ami2,
        valid: valid
      }
    );
  }

  shareWithFriend(ami1: string, listId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/shareWithFriend',
      {
        userId: ami1,
        idListe: listId
      }
    );
  }

  getFriendsOnList(listId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/getFriendsOnList',
      {
        userId : this.auth.getUserInfos()[0],
        idListe: listId
      }
    );
  }

  deleteShareFriend(ami: string, listId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/deleteShareFriend',
      {
        userId : ami,
        idListe: listId
      }
    );
  }

  makeListPublic(publicType: string, listId: string): Observable<Object> {
    return this.http.post(
      '/api/v1' + '/makeListPublic',
      {
        idListe : listId,
        public: publicType
      }
    );
  }  
  
  getYoutube(title: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getYoutube',
      {
        title : title,
        language: this.language
      }
    );
  }

  saveRanking(listId: string, items: Object[]): Observable<any> {
    return this.http.post(
      '/api/v1' + '/saveRanking',
      {
        listId : listId,
        items: items
      }
    );
  }

  makeListRanking(listId: string, isranking: boolean): Observable<any> {
    return this.http.post(
      '/api/v1' + '/makeListRanking',
      {
        listId : listId,
        isranking: isranking
      }
    );
  }

  getSixDegrees(): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getSixDegrees',
      {
        userId:  this.auth.getUserInfos()[0]
      }
    );
  }

  saveSixDegreesResult(game: string, solution: Object[], nbSteps: number): Observable<any> {
    return this.http.post(
      '/api/v1' + '/saveSixDegreesResult',
      {
        userId:  this.auth.getUserInfos()[0],
        game : game,
        solution: JSON.stringify(solution),
        nbSteps : nbSteps 
      }
    );
  }

  getSixDegreesTable(game: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getSixDegreesTable',
      {
        game : game
      }
    );
  }

  getQuizTable(game: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getQuizTable',
      {
        game : game
      }
    );
  }
  
  getSixDegreesResult(game: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getSixDegreesResult',
      {
        userId:  this.auth.getUserInfos()[0],
        game : game
      }
    );
  }

  saveNewQuiz(id: string, title: string, questions: Object): Observable<any> {
    return this.http.post(
      '/api/v1' + '/saveNewQuiz',
      {
        id : id,
        title : title
      }
    );
  }

  saveNewQuizQuestion(id: string,
    questionNumber: number, 
     question: string, 
     prop1: string,
     prop2: string,
     prop3: string,
     image: string,
     answer: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/saveNewQuizQuestion',
      {
        quizId : id,
        questionNumber : questionNumber,
        question : question,
        prop1 : prop1,
        prop2 : prop2,
        prop3 : prop3,
        image : image,
        answer : answer
      }
    );
  }

  getQuizList(): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getQuizList',
      {
        id : this.auth.getUserInfos()[0]
      }
    );
  }

  getExtractList(): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getExtractList',
      {
        id : this.auth.getUserInfos()[0]
      }
    );
  }

  getExtract(id: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getExtract',
      {
        id: id
      }
    );
  }

  getQuiz(id: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getQuiz',
      {
        id: id
      }
    );
  }

  getQuizQuestions(id: string): Observable<any> {
    return this.http.post(
      '/api/v1' + '/getQuizQuestions',
      {
        id: id
      }
    );
  }  

  saveQuizResult(quiz: string, score: number): Observable<any> {
    return this.http.post(
      '/api/v1' + '/saveQuizResult',
      {
        userId:  this.auth.getUserInfos()[0],
        quiz : quiz,
        score: score
      }
    );
  }

  updateUserAccount(userPassword: string, userNewPassword: string, userEmail: string, avatar: string){
    return this.http.post('/api/v1' + '/updateUserAccount', {
      userId: this.auth.getUserInfos()[0],
      password: userPassword,
      newPassword: userNewPassword,
      email: userEmail,
      avatar: avatar
    });
  }

  sendEmailResetPassword(userPasswPseudo: string, userEmail: string){
    return this.http.post('/api/v1' + '/sendEmailResetPassword', {
      pseudo: userPasswPseudo,
      email: userEmail
    });
  }

  updateNetflix(){
    return this.http.post('/api/v1' + '/updateNetflix', {
    });
  }

  getNetflix(){
    return this.http.post('/api/v1' + '/getNetflix', {
    });
  }

  getNetflixMovies(){
    return this.http.post('/api/v1' + '/getNetflixMovies', {
    });
  }

  getNetflixSeries(){
    return this.http.post('/api/v1' + '/getNetflixSeries', {
    });
  }

  getNetflixDeleted(){
    return this.http.post('/api/v1' + '/getNetflixDeleted', {
    });
  }

  getImdbRating(imdbId : string){
    return this.http.post('/api/v1' + '/getImdbRating', {
      imdbId: imdbId
    });
  }

  getPrime(){
    return this.http.post('/api/v1' + '/getPrimeVideo', {
    });
  }

  getPrimeMovies(){
    return this.http.post('/api/v1' + '/getPrimeVideoMovies', {
    });
  }

  getPrimeSeries(){
    return this.http.post('/api/v1' + '/getPrimeVideoSeries', {
    });
  }

  getPrimeDeleted(){
    return this.http.post('/api/v1' + '/getPrimeVideoDeleted', {
    });
  }


}

