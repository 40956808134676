<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel">
      <h1>{{ 'lamentaliste.games.title' | translate }}</h1>
      <h3 class="noabo" *ngIf="userId == '215'">{{ 'lamentaliste.games.NoAbo' | translate }}<br>
        <a href="./#/login">{{ 'lamentaliste.label.connect' | translate}}</a><br>
        <a href="./#/createAccount">{{ 'lamentaliste.noAccount' | translate}}</a>
      </h3>
      <mat-accordion *ngIf="lastSixDegrees && lastQuiz && lastExtract">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span class="presentationTitle">{{ 'lamentaliste.games.resume' | translate }}&nbsp;</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
            <div fxFlex="0 1 calc(40% - 32px)">
              <span class="presentationTitle">{{ 'lamentaliste.games.sixdegrees' | translate }}&nbsp;</span>
              <div class="quizImageContent">
                <div>{{lastSixDegrees.pdate  | date:'dd-MM-yyyy'}}
                  <br>
                  <div class="displayList2"
                    (click)="openSixDegrees(lastSixDegrees.person1, lastSixDegrees.person2, lastSixDegrees.id)">
                    {{lastSixDegrees.name}}</div>
                  <span class="persons">
                    <lamentaliste-sixitem [personid]="lastSixDegrees.person1"></lamentaliste-sixitem>
                  </span>
                  <span class="persons">
                    <lamentaliste-sixitem [personid]="lastSixDegrees.person2"></lamentaliste-sixitem>
                  </span>
                  <div class="success" *ngIf="lastSixDegrees.cptUser == 1">
                    {{ 'lamentaliste.games.sixdegrees.success' | translate }}</div>
                  <div class="counter" *ngIf="lastSixDegrees.cptAll == 0">
                    &nbsp;({{ 'lamentaliste.games.sixdegrees.success0' | translate }})</div>
                  <div class="counter" *ngIf="lastSixDegrees.cptAll == 1">
                    &nbsp;({{ 'lamentaliste.games.sixdegrees.success1' | translate }})</div>
                  <div class="counter" *ngIf="lastSixDegrees.cptAll > 1">
                    &nbsp;({{lastSixDegrees.cptAll}}{{ 'lamentaliste.games.sixdegrees.successMultiple' | translate }})</div>
                  <button mat-button *ngIf="lastSixDegrees.cptAll > 0"
                    (click)="showClassement(lastSixDegrees.id)">{{ 'lamentaliste.sixdegrees.table' | translate }}</button>
                </div>
                <br>
                <div class="classement" *ngIf="nbClassementShow == lastSixDegrees.id">
                  <div *ngFor="let cl of classement">
                    {{cl.pseudo}} : {{cl.nbSteps}} étapes
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex="0 1 calc(25% - 32px)">
              <span class="presentationTitle">{{ 'lamentaliste.games.quiz.title' | translate }}&nbsp;</span>
              <div class="quizImageContent">
                <div>{{lastQuiz.pdate  | date:'dd-MM-yyyy'}}
                  <br><span class="displayList2" (click)="openQuiz(lastQuiz.id)">{{lastQuiz.title}}</span>&nbsp;
                  <div *ngIf="lastQuiz.image != ''" class="quizImage">
                    <lamentaliste-sixitem class="quizImageContent" *ngIf="lastQuiz.imageIsPerson == 0"
                      [quizId]="lastQuiz.id" [movieid]="lastQuiz.image"></lamentaliste-sixitem>
                    <lamentaliste-sixitem *ngIf="lastQuiz.imageIsPerson == 1" [quizId]="lastQuiz.id"
                      [personid]="lastQuiz.image">
                    </lamentaliste-sixitem>
                  </div>
                  <span *ngIf="lastQuiz.score" class="success">
                    <br>{{ 'lamentaliste.games.quiz.displayScore' | translate }}{{lastQuiz.score}} pts</span>
                  <span class="counter" *ngIf="lastQuiz.nbPlayers > 0"><br>
                    {{ 'lamentaliste.games.quiz.averageScore' | translate }} {{lastQuiz.averageScore}}
                    {{ 'lamentaliste.games.quiz.averageScore2' | translate }} {{lastQuiz.nbPlayers}}
                    <span *ngIf="lastQuiz.nbPlayers == 1 ">{{ 'lamentaliste.games.quiz.player' | translate }} </span>
                    <span *ngIf="lastQuiz.nbPlayers > 1 ">{{ 'lamentaliste.games.quiz.players' | translate }} </span>
                  </span>
                  <br><button mat-button *ngIf="lastQuiz.nbPlayers > 0"
                    (click)="showClassementQuiz(lastQuiz.id)">{{ 'lamentaliste.sixdegrees.table' | translate }}</button>
                </div>
                <div class="classement" *ngIf="nbClassementQuizShow == lastQuiz.id">
                  <div *ngFor="let cl of classementQuiz">
                    {{cl.pseudo}} : {{cl.score}} pts
                  </div>
                </div>
              </div>
            </div>
              <div fxFlex="0 1 calc(25% - 32px)">
                  <span class="presentationTitle">{{ 'lamentaliste.games.extract' | translate }}&nbsp;</span>
                  <div class="quizImageContent">
                    <div>{{lastExtract.pdate  | date:'dd-MM-yyyy'}}
                        <br><span class="displayList2" (click)="openExtract(lastExtract.id)">{{lastExtract.title}}</span>&nbsp;
                      <br><span class="displayList2" (click)="openExtract(lastExtract.id)">
                        <img src="assets/extract/{{lastExtract.image}}"/>
      
                      </span>&nbsp;
                    </div>
                  </div>
                </div>


            
            </div>

        </mat-expansion-panel>
      </mat-accordion>

      <!--------------------------------------------------------------------------------------------------------------------------------------------->

      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span class="presentationTitle">{{ 'lamentaliste.games.sixdegrees' | translate }}&nbsp;</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let item of sixdegrees; let i=index">
            <div *ngIf="i < 2 || showAllSixDegrees" class="quizImageContent">
              <div>{{item.pdate  | date:'dd-MM-yyyy'}}
                <br><span class="displayList2"
                  (click)="openSixDegrees(item.person1, item.person2, item.id)">{{item.name}}</span>
                <div *ngIf="i < 2">
                  <span class="persons">
                    <lamentaliste-sixitem [personid]="item.person1"></lamentaliste-sixitem>
                  </span>
                  <span class="persons">
                    <lamentaliste-sixitem [personid]="item.person2"></lamentaliste-sixitem>
                  </span>
                </div>

                <div class="success" *ngIf="item.cptUser == 1">{{ 'lamentaliste.games.sixdegrees.success' | translate }}
                </div>
                <div class="counter" *ngIf="item.cptAll == 0">
                  &nbsp;({{ 'lamentaliste.games.sixdegrees.success0' | translate }})</div>
                <div class="counter" *ngIf="item.cptAll == 1">
                  &nbsp;({{ 'lamentaliste.games.sixdegrees.success1' | translate }})</div>
                <div class="counter" *ngIf="item.cptAll > 1">
                  &nbsp;({{item.cptAll}}{{ 'lamentaliste.games.sixdegrees.successMultiple' | translate }})</div>
                <button mat-button *ngIf="item.cptAll > 0"
                  (click)="showClassement(item.id)">{{ 'lamentaliste.sixdegrees.table' | translate }}</button>
              </div>
              <br>
              <div class="classement" *ngIf="nbClassementShow == item.id">
                <div *ngFor="let cl of classement">
                  {{cl.pseudo}} : {{cl.nbSteps}} étapes
                </div>
              </div>
            </div>
          </div>
          <button mat-stroked-button *ngIf="!showAllSixDegrees"
            (click)="updateShowAllSixDegrees(true)">{{ 'lamentaliste.games.showAll' | translate }}</button>
          <button mat-stroked-button *ngIf="showAllSixDegrees"
            (click)="updateShowAllSixDegrees(false)">{{ 'lamentaliste.games.showReduce' | translate }}</button>
        </mat-expansion-panel>
      </mat-accordion>

      <!--------------------------------------------------------------------------------------------------------------------------------------------->


      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span class="presentationTitle">{{ 'lamentaliste.games.quiz.title' | translate }}&nbsp;</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let item of quiz; let i=index">
            <div *ngIf="i < 2 || showAllQuiz" class="quizImageContent">
              <div>{{item.pdate  | date:'dd-MM-yyyy'}}
                <br><span class="displayList2" (click)="openQuiz(item.id)">{{item.title}}</span>&nbsp;
                <div *ngIf="item.image != '' && i < 2" class="quizImage">
                  <lamentaliste-sixitem class="quizImageContent" *ngIf="item.imageIsPerson == 0" [quizId]="item.id"
                    [movieid]="item.image"></lamentaliste-sixitem>
                  <lamentaliste-sixitem *ngIf="item.imageIsPerson == 1" [quizId]="item.id" [personid]="item.image">
                  </lamentaliste-sixitem>
                </div>
                <span *ngIf="item.score" class="success">
                  <br>{{ 'lamentaliste.games.quiz.displayScore' | translate }}{{item.score}} pts</span>
                <span class="counter" *ngIf="item.nbPlayers > 0"><br>
                  {{ 'lamentaliste.games.quiz.averageScore' | translate }} {{item.averageScore}}
                  {{ 'lamentaliste.games.quiz.averageScore2' | translate }} {{item.nbPlayers}}
                  <span *ngIf="item.nbPlayers == 1 ">{{ 'lamentaliste.games.quiz.player' | translate }} </span>
                  <span *ngIf="item.nbPlayers > 1 ">{{ 'lamentaliste.games.quiz.players' | translate }} </span>
                </span>
                <br><button mat-button *ngIf="item.nbPlayers > 0"
                  (click)="showClassementQuiz(item.id)">{{ 'lamentaliste.sixdegrees.table' | translate }}</button>
              </div>
              <div class="classement" *ngIf="nbClassementQuizShow == item.id">
                <div *ngFor="let cl of classementQuiz">
                  {{cl.pseudo}} : {{cl.score}} pts
                </div>
              </div>
            </div>
          </div>
          <button mat-stroked-button *ngIf="!showAllQuiz"
            (click)="updateShowAllQuiz(true)">{{ 'lamentaliste.games.showAll' | translate }}</button>
          <button mat-stroked-button *ngIf="showAllQuiz"
            (click)="updateShowAllQuiz(false)">{{ 'lamentaliste.games.showReduce' | translate }}</button>
        </mat-expansion-panel>
      </mat-accordion>

      <!--------------------------------------------------------------------------------------------------------------------------------------------->

      <mat-accordion *ngIf="extract">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span class="presentationTitle">{{ 'lamentaliste.games.extract' | translate }}&nbsp;</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let item of extract; let i=index">
            <div *ngIf="i < 2 || showAllExtracts" class="quizImageContent">
              <div>{{item.pdate  | date:'dd-MM-yyyy'}}
                  <br><span class="displayList2" (click)="openExtract(item.id)">{{item.title}}</span>&nbsp;
                <br><span class="displayList2" (click)="openExtract(item.id)">
                  <img src="assets/extract/{{item.image}}"/>

                </span>&nbsp;
              </div>
            </div>
          </div>
          <button mat-stroked-button *ngIf="!showAllExtracts"
            (click)="updateShowAllExtracts(true)">{{ 'lamentaliste.games.showAll' | translate }}</button>
          <button mat-stroked-button *ngIf="showAllExtracts"
            (click)="updateShowAllExtracts(false)">{{ 'lamentaliste.games.showReduce' | translate }}</button>
        </mat-expansion-panel>
      </mat-accordion>

      <!--------------------------------------------------------------------------------------------------------------------------------------------->

      <mat-accordion *ngIf="userId == '200' || userId == '218'">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span class="presentationTitle">{{ 'lamentaliste.games.redacQuiz' | translate }}&nbsp;</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form (ngSubmit)="saveNewQuiz()">
            <mat-form-field><input [(ngModel)]="newQuiz.id" name="listToAdd" type="text" matInput placeholder="ID">
            </mat-form-field><br>
            <mat-form-field class="formField"><input [(ngModel)]="newQuiz.title" name="listToAdd" type="text" matInput
                placeholder="Titre du quiz"></mat-form-field><br>
            <div *ngFor="let question of newQuiz.qList; let i = index">
              Question {{i}}<br>
              <mat-form-field class="formField"><input [(ngModel)]="newQuiz.qList[i].question" name="listToAdd"
                  type="text" matInput placeholder="Question"></mat-form-field><br>
              <mat-form-field class="formField"><input [(ngModel)]="newQuiz.qList[i].prop1" name="listToAdd" type="text"
                  matInput placeholder="Prop1"></mat-form-field><br>
              <mat-form-field class="formField"><input [(ngModel)]="newQuiz.qList[i].prop2" name="listToAdd" type="text"
                  matInput placeholder="Prop2"></mat-form-field><br>
              <mat-form-field class="formField"><input [(ngModel)]="newQuiz.qList[i].prop3" name="listToAdd" type="text"
                  matInput placeholder="Prop3"></mat-form-field><br>
              <mat-form-field><input [(ngModel)]="newQuiz.qList[i].image" name="listToAdd" type="text" matInput
                  placeholder="image"></mat-form-field><br>
              <mat-form-field><input [(ngModel)]="newQuiz.qList[i].answer" name="listToAdd" type="text" matInput
                  placeholder="Réponse"></mat-form-field><br>
              <br><br>
            </div>
            <button mat-raised-button color="accent">
              {{ 'lamentaliste.label.add' | translate}}
            </button>
          </form>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</div>