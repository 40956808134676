import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lamentaliste-extract',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss']
})
export class ExtractComponent implements OnInit {
  public id: string;
  public image: string;
  public original: string;
  public responseId: string;
  public showOri: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    public snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.activatedRoute.fragment.subscribe(fragment => { });
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'];
      this.getExtract();
    });
  }

  private getExtract(){
    this.data.getExtract(this.id).subscribe(res => {
      console.log(res);
      this.image = res["0"].image;
      this.original = res["0"].original;
      this.responseId = res["0"].responseId;
    });
  }

  public checkProposition(res: any) {
    console.log("id : " + res.id);
    console.log("responseId : " + this.responseId);
    console.log("-->" + res.id == this.responseId);
    if( res.id == this.responseId ){
      this.snackBar.open(
        "GAGNE !",
        null,
        {
          duration: 2000,
          panelClass: 'snackbarOK'
        }
      );
      this.showOri = true;
    } else {
      this.snackBar.open(
        "Ce n'est pas la bonne réponse",
        null,
        {
          duration: 2000,
          panelClass: 'snackbarError'
        }
      );  
    }
  }
}
