import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

class Step {
  movie: string;
  person: string;
}

@Component({
  selector: 'lamentaliste-sixdegrees',
  templateUrl: './sixdegrees.component.html',
  styleUrls: ['./sixdegrees.component.scss']
})
export class SixdegreesComponent implements OnInit {
  public personOneId: string;
  public personObjectiveId: string;
  public game: string;
  public lastPersonId: string = "";
  public lastMovieId: string = "";
  public steps: Step[] = [];
  public nextIsPerson: boolean = false;
  public showSuccess: boolean = false;
  public nbSteps: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.activatedRoute.fragment.subscribe(fragment => { });
    this.activatedRoute.queryParams.subscribe(params => {
      this.game = params['game'];
      this.personOneId = params['id1'];
      this.personObjectiveId = params['id2'];
      this.lastPersonId = this.personOneId;
      this.data.getSixDegreesResult(this.game).subscribe(res => {
        if (res["0"]) {
          if( res["0"].isOld){
            this.addFirst();
          }
            let obj: string = JSON.parse(res["0"].solution);
          console.log(obj);
          for (let obj of JSON.parse(res["0"].solution)) {
            let step: Step = new Step();
            if (obj["movie"] != null) {
              step.movie = obj["movie"];
            } else {
              step.person = obj["person"];
              console.log("person : " + step.person);
              console.log("this.lastPersonId : " + this.lastPersonId);
              if( step.person == this.personObjectiveId ){
                this.showSuccess = true;
              }
              if( res["0"].isOld) {
                this.steps.push(step); // push 2 times if person
              }
            }
            this.steps.push(step);
          }
        } else {
          this.addFirst();
        }
      })
    });


  }

  public addFirst(){
    let step: Step = new Step();
    step.person = this.personOneId;
    this.steps.push(step);
  }

  /**
   * 
   * @param newItem 
   */
  public addItem(newItem: any) {
    let step: Step = new Step();
    if (this.nextIsPerson) {
      this.processAddPerson(newItem);
    } else {
      this.processAddMovie(newItem);
    }
  }

  /**
   * 
   * @param newItem 
   */
  private processAddMovie(newItem: any) {
    let step: Step = new Step();
    this.data.getMovieCredits(newItem.id).subscribe(rep => {
      if (this.isPersonInMovie(rep, this.lastPersonId)) {
        step.movie = newItem.id;
        this.steps.push(step);
        this.nextIsPerson = !this.nextIsPerson;
        this.lastMovieId = newItem.id;
        this.data.getMovieCredits(this.lastMovieId).subscribe(rep => {
          if (this.isPersonInMovie(rep, this.personObjectiveId)) {
            let step: Step = new Step();
            step.person = this.personObjectiveId;
            this.steps.push(step);
            this.Finished();
          }
        });
      } else {
        this.errorMsg();
      }
    });
  }

  /**
   * 
   * @param newItem 
   */
  private processAddPerson(newItem: any) {
    let step: Step = new Step();
    this.data.getMovieCredits(this.lastMovieId).subscribe(rep => {
      if (this.isPersonInMovie(rep, newItem.id)) {
        step.person = newItem.id;
        this.steps.push(step);
        this.steps.push(step); // push 2 times if person
        this.nextIsPerson = !this.nextIsPerson;
        this.lastPersonId = newItem.id;
      } else {
        this.errorMsg();
      }
    });
  }

  /**
   * 
   */
  private errorMsg() {
    this.snackBar.open(
      "N'a pas joué dans ce film",
      null,
      {
        duration: 2000,
        panelClass: 'snackbarError'
      }
    );
  }

  /**
   * 
   * @param rep 
   */
  private isPersonInMovie(movie: Object, personId: string) {
    for (let item of movie["cast"]) {
      if (item.id == personId) {
        return true;
      }
    }
    return false;
  }

  public onBackspace() {
    if (this.steps.length > 0) {
      this.nextIsPerson = !this.nextIsPerson;
    }
    this.steps.pop();
    if( this.steps.length == 0){
      this.lastPersonId = this.personOneId;
    } else {
      if(this.nextIsPerson && this.showSuccess == false ){
        this.steps.pop();
      }
      this.showSuccess = false;
      let step : Step = this.steps[this.steps.length - 1];
      if(step.movie != null ){
        this.lastMovieId = step.movie;
      } else {
        this.lastPersonId = step.person;
      }  
    }
  }

  private Finished() {
    this.showSuccess = true;
    this.snackBar.open(
      "Vous avez trouvé !",
      null,
      {
        duration: 2000,
        panelClass: 'snackbarOK'
      }
    );
    this.data.saveSixDegreesResult(this.game, this.steps, this.steps.length / 3).subscribe(rep => {
    });
  }

}
