import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../data.service';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lamentaliste-netflix',
  templateUrl: './netflix.component.html',
  styleUrls: ['./netflix.component.scss']
})
export class NetflixComponent implements OnInit {
  public netflixList : Object[] = [];
  public filterView0 : String = "1";
  public filterView : String = "1";
  public filterViewPrime : String = "1";
  public displayView : String = "2";
  public mapImdbRating : Map<String, String> = new Map<String, String>();
  public showImdb : boolean = true;
  public lastDate : String = ""; // last date for display on list. 
  entities = require("entities");
  columnsToDisplay = ['date', 'image', 'type','title', 'country', 'imdbRating', 'seeOnNetflix'];
  dataSource = new MatTableDataSource<Object>(this.netflixList);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(    private data: DataService,
    private activatedRoute: ActivatedRoute  ) {
   }

  ngOnInit() {
    this.activatedRoute.fragment.subscribe(fragment => { });
    this.activatedRoute.queryParams.subscribe(params => {
      this.filterView0 = params['isPrime'];
      this.onFilterPlatformChange();
    });
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public showImdbRatings(){
    this.showImdb = !this.showImdb;
 /*   if( this.mapImdbRating.size == 0 ){
      this.updateImdbRatings();
    }*/
  }


  public onFilterPlatformChange(){
    this.lastDate = "";
    if(  this.filterView0 == "1"){
      this.getNetflixList();
    } else {
      this.getPrimeList();
    }
  }

  public onFilterChange(){
    //this.updateImdbRatings();
    if( this.filterView == "1"){
      this.getNetflixList();
    } else if( this.filterView == "2" ) {
      this.getNetflixMovies();
    } else if( this.filterView == "3" ) {
      this.getNetflixSeries();
    } else if( this.filterView == "4" ) {
      this.getNetflixDeleted();
    }
  }

  public onFilterChangePrime(){
    //this.updateImdbRatings();
    if( this.filterViewPrime == "1"){
      this.getPrimeList();
    } else if( this.filterViewPrime == "2" ) {
      this.getPrimeMovies();
    } else if( this.filterViewPrime == "3" ) {
      this.getPrimeSeries();
    } else if( this.filterViewPrime == "4" ) {
      this.getPrimeDeleted();
    }
  }

  public getPrimeList() {
    this.data.getPrime().subscribe(rep => {
      rep = this.updateTitle(rep);
      this.netflixList = Object.assign([], rep);
      console.log(this.netflixList);
      this.dataSource = new MatTableDataSource<Object>(this.netflixList);
      this.dataSource.paginator = this.paginator;
    });    
  }

  public getNetflixList(){
    this.data.getNetflix().subscribe(rep => {
      rep = this.updateTitle(rep);
      this.netflixList = Object.assign([], rep);
      console.log(this.netflixList);
      this.dataSource = new MatTableDataSource<Object>(this.netflixList);
      this.dataSource.paginator = this.paginator;
    });
  }

  public updateTitle(obj : any) : any{
    obj.forEach(function (value) {
      if( value.frenchTitle != '' ){
        value.title = value.frenchTitle;
      }
      if( value.imdbId != ''){
        value.image = "assets/postersStream/" + value.imdbId + ".jpg";
      }

      //value.title = utf8.decode(entities.decodeHTML(itemStream.title));
    });
    return obj;
  }

  public getNetflixMovies(){
    this.data.getNetflixMovies().subscribe(rep => {
      this.fillDataSource(rep);
    });
  }

  public getNetflixSeries(){
    this.data.getNetflixSeries().subscribe(rep => {
      this.fillDataSource(rep);
    });
  }

  public getNetflixDeleted(){
    this.data.getNetflixDeleted().subscribe(rep => {
      this.fillDataSource(rep);
    });
  }

  public getPrimeMovies(){
    this.data.getPrimeMovies().subscribe(rep => {
      this.updateTitle(rep);
      this.fillDataSource(rep);
    });
  }

  public getPrimeSeries(){
    this.data.getPrimeSeries().subscribe(rep => {
      this.fillDataSource(rep);
    });
  }

  public getPrimeDeleted(){
    this.data.getPrimeDeleted().subscribe(rep => {
      this.fillDataSource(rep);
    });
  }

  private fillDataSource(obj : Object){
    this.netflixList = Object.assign([], obj);
    this.dataSource = new MatTableDataSource<Object>(this.netflixList);
    this.dataSource.paginator = this.paginator;
  }

  public isNewDate(onDate : String){
    if( this.lastDate != onDate ){
      this.lastDate = onDate;
      return true;
    } else {
      return false;
    }
  }
}
