import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lamentaliste-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output() uploaded = new EventEmitter<Object>();
  @Output() uploadedClose = new EventEmitter<Object>();
  @Input() isQuiz: string;
  @Input() isNormalSearch: string;
  @Input() showAddMulti: boolean;
  @Input() showAddMovie: boolean;
  @Input() showAddTV: boolean;
  @Input() showAddPerson: boolean;
  @Input() listId: string;
  @Input() items: Object[];

  language: string;
  queryMulti: string;
  queryMovie: string;
  querySerie: string;
  queryPerson: string;
  result: any;
  personDetails: any;
  searchIndex: number = 0;
  searchSize: number = 0;
  backgroundImage: SafeStyle;

  displayedColumns: string[] = ['image', 'category', 'title'];
  dataSource = null;

  constructor(
    private data: DataService,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.language = this.data.getLanguage();
  }

  ngOnChanges() {
    this.result = null;
    this.queryMovie = null;
    this.querySerie = null;
  }

  handleBrokenImage(event:any) {
    event.target.src = '../../assets/images/noimg.png'
}

  nolink() {
    this.snackBar.open('La page pour les personnalités est en cours de développement.', null, {
      duration: 6000,
      panelClass: 'snackbarWarning'
    });
}

  public updateSearchMulti() {
    this.queryMovie = '';
    this.querySerie = '';
    if (this.queryMulti == '') {
      this.result = null;
    } else {
      this.data.getMulti(this.queryMulti).subscribe(searchResult => {
        if (searchResult["total_results"] == 0) {
          this.result = [];
        } else {
          this.result = Object.assign([], searchResult)["results"];
          console.log(this.result);
        }
        this.searchIndex = 0;
        this.dataSource = this.result;
      });
    }
  }

  public updateSearchMovie() {
    this.queryMulti = '';
    this.querySerie = '';
    if (this.queryMovie == '') {
      this.result = null;
    } else {
      this.data.getMovie(this.queryMovie).subscribe(searchResult => {
        if (searchResult["total_results"] == 0) {
          this.result = [];
        } else {
          this.result = Object.assign([], searchResult)["results"];
          console.log(this.result);
        }
        this.searchIndex = 0;
      });
    }
  }

  public updateSearchSerie() {
    this.queryMulti = '';
    this.queryMovie = '';
    if (this.querySerie == '') {
      this.result = null;
    } else {
      this.data.getTV(this.querySerie).subscribe(searchResult => {
        if (searchResult["total_results"] == 0) {
          this.result = [];
        } else {
          this.result = Object.assign([], searchResult)["results"];
          console.log(this.result);
        }
        this.searchIndex = 0;
      });
    }
  }

  public updateSearchPerson() {
    this.queryMulti = '';
    this.queryMovie = '';
    if (this.queryPerson == '') {
      this.result = null;
    } else {
      this.data.getPerson(this.queryPerson).subscribe(searchResult => {
        if (searchResult["total_results"] == 0) {
          this.result = [];
        } else {
          this.result = Object.assign([], searchResult)["results"];
          this.getPersonDetails();
          console.log(this.result);
        }
        this.searchIndex = 0;
      });
    }
  }

  private furtherOperationsOnChangeIndex(){
    if( this.showAddPerson ){
      this.getPersonDetails();
    }
  }


  private getPersonDetails(){
    this.data.getPersonById(this.result[this.searchIndex].id).subscribe(rep => {
      this.personDetails = rep;
      console.log(rep);
    });
  }

  public backClicked() {
    if (this.searchIndex > 0) {
      this.searchIndex = this.searchIndex - 1;
      this.furtherOperationsOnChangeIndex();
    }
  }

  public firstClicked() {
    this.searchIndex = 0;
    this.furtherOperationsOnChangeIndex();
  }

  public forwardClicked() {
    let res = <Array<Object>>this.result;
    if (this.searchIndex < res.length - 1) {
      this.searchIndex = this.searchIndex + 1;
      this.furtherOperationsOnChangeIndex();
    }
  }

  public lastClicked() {
    let res = <Array<Object>>this.result;
    this.searchIndex = res.length - 1;
    this.furtherOperationsOnChangeIndex();
  }

  public addClicked() {
    let category: number = 0;
    let title, release_date: string;
    if (this.showAddMovie) {
      category = 1;
      title = this.result[this.searchIndex].title;
      release_date = this.result[this.searchIndex].release_date;
    } else {
      category = 2;
      title = this.result[this.searchIndex].name;
      release_date = this.result[this.searchIndex].first_air_date;
    }
    if (this.isQuiz) {
      this.uploaded.emit(this.result[this.searchIndex]);
    } else {
      this.data.addMovieToDB(
        title,
        this.result[this.searchIndex].id,
        release_date,
        this.result[this.searchIndex].genre_ids,
        this.listId,
        "https://image.tmdb.org/t/p/w185" + this.result[this.searchIndex].poster_path,
        category
      ).subscribe(res => {
        console.log(res);
        let newMovie = {};
        newMovie["Id"] = res;
        newMovie["Titre"] = title;
        newMovie["checkperso"] = 0;
        newMovie["idListe"] = this.listId;
        newMovie["image"] = this.result[this.searchIndex].id;
        newMovie["supprime"] = 0;
        newMovie["texteperso"] = null;
        newMovie["tmdbId"] = this.result[this.searchIndex].id;
        newMovie["categorie"] = category;
        console.log(newMovie);
        this.uploaded.emit(newMovie);
      })
    }

  }

  public onSearchTypeButton(searchType : number){
      this.showAddMulti = searchType == 0;
      this.showAddMovie = searchType == 1;
      this.showAddTV = searchType == 2;
      this.showAddPerson = searchType == 3;
      this.queryMovie = '';
      this.querySerie = '';
      this.queryPerson = '';
      this.result = null;
  }

  public onClose() {
    this.result = [];
    this.uploadedClose.emit();
  }


}

