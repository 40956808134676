import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'lamentaliste-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.scss']
})
export class ShareListComponent implements OnInit {
  @Input() listId: string;
  @Input() public: string;
  @Output() uploadedCloseShare = new EventEmitter<Object>();
  @Output() uploadedPublicStatus = new EventEmitter<Object>();

  friends : Object[];
  friendsNotShared : Object[];
  friendsSharedWith : Object[];
  storeSubscription: Subscription;
  selectedMode: string = '1';
  selectedFriend: string;
  constructor(
    private data: DataService
  ) { 
  }

  ngOnInit() {
    this.sharingInfos();
  }

  sharingInfos(){
    this.getFriendsOnList();
  }

  getFriendsOnList(){
    this.data.getFriendsOnList(this.listId).subscribe(rep => {
      console.log(rep);
      this.friendsNotShared = Object.assign([], rep[1]);
      this.friendsSharedWith = Object.assign([], rep[0]);
    });    
  }

  shareWithFriend(ami: string){
    this.data.shareWithFriend(ami, this.listId).subscribe(rep => {
      console.log(rep);
      this.sharingInfos();
    });
  }

  deleteShareFriend(ami: string){
    this.data.deleteShareFriend(ami, this.listId).subscribe(rep => {
      console.log(rep);
      this.sharingInfos();
    });
  }

  makeListPublic(publicType: string){
    this.data.makeListPublic(publicType, this.listId).subscribe(rep => {
      console.log(rep);
      this.public = publicType;
      this.uploadedPublicStatus.emit();
    });
  }

  public onClose(){
    this.uploadedCloseShare.emit();
  }

}
