import { Directive } from '@angular/core';

@Directive({
  selector: '[lamentalisteListItem]'
})
export class ListItemDirective {

  constructor() { }

}
