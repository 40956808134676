import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectAuth, AppState } from '@app/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
class Slide {
  img: string;
  link:string;
}

@Component({
  selector: 'lamentaliste-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  userId: string = localStorage.getItem('userId');
  userName: string = localStorage.getItem('userName');
  
  storeSubscription: Subscription;

  public id: string;
  notifiedLists: Object[];
  userLists: Object[];
  publicLists: Object[];
  sharedLists: Object[];
  quizList: Object[] = [];
  sixdegreesList: Object[];
  randomValueForTitle: number;
  posterid: string;
  tmdbId: number;
  listToAdd: string = '';
  nbFriendRequest: number = 0;
  slides: Slide[] = new Array(2);
  carouselCurrentLink: string = "#/quizfront";
  userAvatar : string = "";

  // for table
  columnsToDisplay = ['date', 'title', 'score', 'averageScore', 'nbPlayers'];
  dataSource = new MatTableDataSource<Object>(this.quizList);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(
    private data: DataService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loadCarousel();
    this.refreshTitle();
    this.storeSubscription = this.store
      .pipe(select(selectAuth))
      .subscribe(auth => {
        if (auth.userId != undefined && auth.userId != '') {
          this.userId = auth.userId;
          this.userName = auth.userName;
          this.data.getAllUserInfos().subscribe(rep => {
            let user : Object = Object.assign([], rep);
            if( user[0].avatar != null && user[0].avatar != '' ){
              this.userAvatar = user[0].avatar;
            } else {
              this.userAvatar = null;
            }
          });
        } else {
          this.userId = '215';
          this.userName = 'Visiteur';

        }
        //this.getNotifiedLists();
        this.getOwnerLists();
        this.getPublicLists();
        this.getSharedLists();
        this.getQuizList();
        this.getSixDegrees();

        this.getFriendsRequests();
      });
  }

  public getNetflixList(){
    this.data.updateNetflix().subscribe(rep => {
      console.log('LISTE : ' + rep);
    });
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }

  loadCarousel(){
    this.slides[0] = new Slide();
    this.slides[0].img="assets/carousel/carousel4.png";
    this.slides[0].link="#";
    this.slides[1] = new Slide();
    this.slides[1].img="assets/carousel/carousel5.png";
    this.slides[1].link="#/listContent?listId=5d63e272abb7f";
    this.slides[2] = new Slide();
    this.slides[2].img="assets/carousel/carousel1.png";
    this.slides[2].link="#/quizfront";
    this.carouselCurrentLink = this.slides[0].link;
    this.slides[3] = new Slide();
    this.slides[3].img="assets/carousel/carousel2.png";
    this.slides[3].link="#/listContent?listId=5d59a8245aaf2";
    this.slides[4] = new Slide();
    this.slides[4].img="assets/carousel/carousel3.png";
    this.slides[4].link="#/listContent?listId=5d59a81935a62";
  }

  public clickSlide(slide : string){
    window.location.href = this.carouselCurrentLink;
  }

  public updateEvent(index: number){
    this.carouselCurrentLink = this.slides[index].link;
  }

  getFriendsRequests(){
    if( this.userId != '215' ){
      this.data.getFriendsToValidate().subscribe(rep => {
        let friends = Object.assign([], rep);
        this.nbFriendRequest = friends.length;
      });  
    }
  }
/*
  getNotifiedLists(){
    this.data.getNotifiedLists().subscribe(rep => {
      this.notifiedLists = Object.assign([], rep);
    });
  }*/

  getOwnerLists() {
    console.log("User Id = " + this.userId ) ;
    console.log("User Name = " + this.userName ) ;

    this.data.getOwnerLists(this.userId).subscribe(rep => {
      this.userLists = Object.assign([], rep);
    });
  }
 
  getPublicLists() {
    this.data.getPublicLists(this.userId).subscribe(rep => {
      this.publicLists = Object.assign([], rep);
    });
  }
  getSharedLists() {
    this.data.getSharedLists(this.userId).subscribe(rep => {
      this.sharedLists = Object.assign([], rep);
    });
  }

  getQuizList(){
    this.data.getQuizList().subscribe(rep => {    
      this.quizList = Object.assign([], rep);
      this.dataSource = new MatTableDataSource<Object>(this.quizList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getSixDegrees( ){
    this.data.getSixDegrees().subscribe(rep => {
      this.sixdegreesList = Object.assign([], rep);
    });
  }

  openList(id: string) {
    window.location.href = '#/listContent?listId=' + id;
  }
  
  public openSixDegrees(id1: string, id2: string, game: string){
    window.location.href = '#/sixdegrees?id1=' + id1 + '&id2=' + id2 + '&game=' + game;
  }

  public openQuiz(id: string){
    window.location.href = '#/quiz?id=' + id;
  }

  addList() {
    this.data.addList(this.listToAdd).subscribe(rep => {
      this.getOwnerLists();
      this.listToAdd = "";
      this.snackBar.open('Liste créée', null, {
        duration: 2000,
        panelClass: 'snackbarOK'
      });

    });
  }

  refreshTitle() {
    let oldRandomValueForTitle: number = this.randomValueForTitle;
    while (oldRandomValueForTitle == this.randomValueForTitle) {
      this.randomValueForTitle = Math.floor((Math.random() * 9) + 1);
    }
    switch (this.randomValueForTitle) {
      case 1:
        this.posterid = "assets/posters/9487.jpg";
        this.tmdbId = 9487;
        break;
      case 2:
        this.posterid = "assets/posters/9487.jpg";
        this.tmdbId = 9487;
        break;
      case 3:
        this.posterid = "assets/posters/185.jpg";
        this.tmdbId = 185;
        break;
      case 4:
        this.posterid = "assets/posters/105.jpg";
        this.tmdbId = 105;
        break;
      case 5:
        this.posterid = "assets/posters/105.jpg";
        this.tmdbId = 105;
        break;
      case 6:
        this.posterid = "assets/posters/812.jpg";
        this.tmdbId = 812;
        break;
      case 7:
        this.posterid = "assets/posters/57800.jpg";
        this.tmdbId = 57800;
        break;
      case 8:
        this.posterid = "assets/posters/238.jpg";
        this.tmdbId = 238;
        break;
      case 9:
        this.posterid = "assets/posters/89.jpg";
        this.tmdbId = 89;
        break;
    }
  }

}
