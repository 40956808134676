<div class="background">
  <div class="gradient ">
    <div class="container formFields backgroundPanel">
      <h1>{{ 'lamentaliste.title.createAccount' | translate }}</h1>
      <h2>{{ 'lamentaliste.title.sub.createAccount' | translate }}</h2>
      <form ngNativeValidate (submit)="createAccount()">
        <mat-form-field class="mat-input-placeholder">
            <input [(ngModel)]="userId" name="userId" matInput placeholder="{{ 'lamentaliste.label.id' | translate}}" required>
        </mat-form-field>
        <br><br>
        <mat-form-field class="mat-input-placeholder">
            <input type="password" [(ngModel)]="userPassword" name="userPassword" matInput placeholder="{{ 'lamentaliste.label.password' | translate}}" required>
        </mat-form-field>
        <br><br>
        <mat-form-field class="mat-input-placeholder">
            <input tpye="email" [(ngModel)]="userEmail" name="userEmail" matInput placeholder="{{ 'lamentaliste.label.email' | translate}}" >
        </mat-form-field>
        <br><br>
        <button [disabled]="isSubmitForgottenPasswDisabled()" mat-raised-button color="accent" type="submit">
          {{ 'lamentaliste.label.validate' | translate}}
        </button>
        <br><br>
        <div class="text-xs-center">
          <re-captcha class="g-recaptcha" (resolved)="resolved($event)" siteKey="6Lc0YdgUAAAAAJD1F_1uTSEulPVZmKKVS2ixw6QV"></re-captcha>
        </div>
      </form>
    </div>
  </div>
</div>

  
