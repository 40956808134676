import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Avatar {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'lamentaliste-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public isModifyPassword : boolean = false;
  public userPassword : string = '';
  public userPasswordModify : string = '';
  public userPasswordConfirm : string = '';
  public userEmail : string;
  public userAvatar : string;
  public selectedAvatar : string;
  public avatars: Avatar[] = [];
  public nbAvatarsMale: number = 25;
  public nbAvatarsFemale: number = 24;
  public showAvatars: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private data: DataService
    ) {
    this.data.getAllUserInfos().subscribe(rep => {
      console.log(rep);
      let user : Object = Object.assign([], rep);
      if( user[0].email != null && user[0].email != '' ){
        this.userEmail = user[0].email;
      } else {
        this.userEmail = null;
      }

      if( user[0].avatar != null && user[0].avatar != '' ){
        this.userAvatar = user[0].avatar;
      } else {
        this.userAvatar = null;
      }

      this.userPassword = null;
    }
      
      )
   }

  ngOnInit() {
    for (let i = 0; i < this.nbAvatarsMale ; i++) {
      let path : string;
      if( i < 9 ){
        path = 'assets/avatars/10' + String(i+1) + '.svg';
      } else {
        path = 'assets/avatars/1' + String(i+1) + '.svg';
      }
      this.avatars[i] = {value: String(i), viewValue: path};
    }

    for (let i = 0; i < this.nbAvatarsFemale ; i++) {
      let path : string;
      if( i < 9 ){
        path = 'assets/avatars/20' + String(i+1) + '.svg';
      } else {
        path = 'assets/avatars/2' + String(i+1) + '.svg';
      }
      this.avatars[i + this.nbAvatarsMale] = {value: String(i + this.nbAvatarsMale), viewValue: path};
    }
  }

  public selectAvatar(i: number){
    this.selectedAvatar = String(i);
    this.userAvatar = this.avatars[i].viewValue;
    this.showAvatars = !this.showAvatars;
  }

  public onClickShowAvatar(){
    this.showAvatars = !this.showAvatars;
  }

  public modifyAccount() {
    let avatarImg : string ="";
    if( this.selectedAvatar != null ){
      avatarImg =  this.avatars[this.selectedAvatar].viewValue;
    }
    if( this.isModifyPassword == true){
      if( this.userPasswordModify === this.userPasswordConfirm ) {
        this.data.updateUserAccount(this.userPassword, this.userPasswordConfirm, this.userEmail, null).subscribe(rep => {
          if( rep == 'OK'){
            this.snack(false, "Les données ont été mises à jour");
            window.location.href = '#/home';
          } else if( rep == 'MAUVAIS MDP'){
            this.snack(true, "Le mot de passe existant est erroné");
          } else {
            this.snack(true, "Une erreur est survenue");
          }
        })
      } else {
        this.snack(true, "Le mot de passe modifé et sa confirmation ne correspondent pas.");
      }
    } else {
      this.data.updateUserAccount(this.userPassword, null, this.userEmail, avatarImg).subscribe(rep => {
        if( rep == 'OK'){
          this.snack(false, "Les données ont été mises à jour");
          window.location.href = '#/home';
        } else if( rep == 'MAUVAIS MDP'){
          this.snack(true, "Le mot de passe existant est erroné");
        } else {
          this.snack(true, "Une erreur est survenue");
        }
      })
    }
  }

  public updateModifyPassword(val: boolean){
    this.isModifyPassword = val;
  }


  private snack(isError : boolean, msg : string){
    if( isError ){
      this.snackBar.open(
        msg,
        null,
        {
          duration: 2000,
          panelClass: 'snackbarError'
        }
      );        
    } else {
      this.snackBar.open(
        msg,
        null,
        {
          duration: 2000,
          panelClass: 'snackbarOK'
        }
      );        

    }
  }
}
