<div class="background">
  <div class="gradient">
    <div class="container">
      <h1>{{ 'lamentaliste.title' | translate }}</h1>

      <button (click)="onClickMe()">Click me!</button>
      {{clickMessage}}
      <div class="actions">
        <a [ngClass]="routeAnimationsElements" mat-raised-button class="actions-main" color="primary" routerLink="../features">
          {{ 'lamentaliste.menu.features' | translate }}
        </a>
        <a mat-raised-button class="actions-main" color="accent" routerLink="../examples" [ngClass]="routeAnimationsElements">
          {{ 'lamentaliste.menu.examples' | translate }}
        </a>
        <a mat-raised-button class="actions-main" color="warn" routerLink="../settings" [ngClass]="routeAnimationsElements">
          {{ 'lamentaliste.about.change-theme' | translate }}
        </a>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
      </div>
    </div>
  </div>
</div>
<div class="container">
</div>
