<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="background">
  <div class="gradient">
    <div class="container">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span class="presentationTitle">
                <mat-icon class="menuIcon">search</mat-icon>&nbsp;Recherche
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <lamentaliste-search [showAddMulti]="true" [showAddMovie]="false" [showAddTV]="false" [showAddPerson]="false"
            [isNormalSearch]="true">
          </lamentaliste-search>

        </mat-expansion-panel>
      </mat-accordion>
      <br>
      <!-- lists in preview -->
      <mat-card>
          <img class="titleImage" src="assets/imagesTitle/{{randomValueForTitle}}.png"><br>
          <!--<div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

            <div fxFlex="0 1 calc(60% - 32px)">
            <mat-carousel *ngIf="slides" timings="250ms ease-in" [autoplay]="true" interval="3000" color="accent"
              maxWidth="auto" proportion="25" slides="5" [loop]="true" [hideArrows]="false" [hideIndicators]="true"
              [useKeyboard]="false" [useMouseWheel]="false" orientation="ltr" (change)="updateEvent($event)"
              (dblclick)="clickSlide($event)">
              <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.img"
                overlayColor="#00000040" [disabled]="false" [hideOverlay]="true">
              </mat-carousel-slide>
            </mat-carousel>
          </div>
          <div fxFlex="0 1 calc(35% - 32px)">
            <div class="title">
                  <button class="title2" mat-button (click)="refreshTitle()">{{ 'lamentaliste.refresh' | translate }}</button>
                  <img class="titlePoster" src="{{posterid}}">
            </div>
          </div>
        </div>
        -->
        Des films, des séries... et des jeux !
        <br>
        <!-- entete part 1-->
        <span class="presentationTitle">{{ 'lamentaliste.presentationTitle' | translate }}&nbsp;</span>
        <a href="#/friends">
          <span class="friendInfo"
            *ngIf="nbFriendRequest == 1">{{ 'lamentaliste.friendRequest' | translate }}</span>
          <span class="friendInfo"
            *ngIf="nbFriendRequest > 1">{{ 'lamentaliste.multipleFriendRequest' | translate }}</span>
        </a>
        {{ 'lamentaliste.presentationText' | translate }}<br>
        {{ 'lamentaliste.presentationText2' | translate }}<br><br>


        <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

          <div fxFlex="0 1 calc(48% - 32px)">
            <h1>Listes</h1>

            <mat-accordion>
              <mat-expansion-panel *ngIf="userLists" [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="list-title">
                    {{ 'lamentaliste.myLists' | translate }}&nbsp;
                    <span class="list-description">({{userLists.length}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <form (ngSubmit)="addList()">
                  <mat-form-field>
                    <input [(ngModel)]="listToAdd" name="listToAdd" type="text" matInput
                      placeholder=" {{ 'lamentaliste.addList' | translate }} ">
                  </mat-form-field>&nbsp;&nbsp;
                  <button mat-raised-button color="accent" *ngIf="listToAdd.length > 0">
                    {{ 'lamentaliste.label.add' | translate}}
                  </button>
                </form>
                <ng-container *ngFor="let list of userLists">
                  <div class="displayList2" (click)="openList(list.id)">
                    <span matTooltip="{{ 'lamentaliste.share.isPrivate' | translate }}">
                      <mat-icon *ngIf="list.public == '0'">people_outline</mat-icon>
                    </span>
                    <span matTooltip="{{ 'lamentaliste.share.isPublic' | translate }}">
                      <mat-icon *ngIf="list.public == '1'">group</mat-icon>
                    </span>
                    <span matTooltip="{{ 'lamentaliste.share.isCollaborative' | translate }}">
                      <mat-icon *ngIf="list.public == '2'">group_add</mat-icon>
                    </span>
                    <span matTooltip="{{ 'lamentaliste.share.isCollaborativeForAll' | translate }}">
                      <mat-icon *ngIf="list.public == '3'">public</mat-icon>
                    </span>
                    &nbsp;<span matTooltip="{{ 'lamentaliste.isRanking' | translate }}">
                      <mat-icon *ngIf="list.isranking == '1'">format_list_numbered</mat-icon>
                    </span>
                    &nbsp;{{list.Nom}} <span class="counter">({{list.countFilms}}
                      {{ 'lamentaliste.element' | translate }}<span *ngIf="list.countFilms > 1">s</span>)</span>
                  </div>
                </ng-container>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="publicLists" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="list-title">
                    {{ 'lamentaliste.publicLists' | translate }}&nbsp;
                    <span class="list-description">({{publicLists.length}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                  <ng-container *ngFor="let plist of publicLists">
                    <div class="displayList" fxFlex="0 1 calc(48% - 32px)" (click)="openList(plist.id)">
                      <span matTooltip="{{ 'lamentaliste.isRanking' | translate }}">
                        <mat-icon *ngIf="plist.isranking == '1'">format_list_numbered</mat-icon>
                      </span>{{plist.Nom}}<br>
                      <span class="counter">({{plist.countFilms}} {{ 'lamentaliste.element' | translate }}<span
                          *ngIf="plist.countFilms > 1">s</span>) {{ 'lamentaliste.by' | translate }} {{plist.pseudo}}</span>
                    </div>
                  </ng-container>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="sharedLists" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="list-title">
                    {{ 'lamentaliste.sharedLists' | translate }}&nbsp;
                    <span class="list-description">({{sharedLists.length}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                  <ng-container *ngFor="let slist of sharedLists">
                    <div class="displayList" fxFlex="0 1 calc(48% - 32px)" (click)="openList(slist.id)">
                      <span matTooltip="{{ 'lamentaliste.isRanking' | translate }}">
                        <mat-icon *ngIf="slist.isranking == '1'">format_list_numbered</mat-icon>
                      </span>{{slist.Nom}}<br>
                      <span class="counter">({{slist.countFilms}} {{ 'lamentaliste.element' | translate }}<span
                          *ngIf="slist.countFilms > 1">s</span>) {{ 'lamentaliste.by' | translate }} {{slist.pseudo}}</span>
                    </div>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <!--<ng-container *ngFor="let list of notifiedLists; let i=index">
              <div *ngIf="i<3" class="displayList2" (click)="openList(list.id)">
                {{list.Nom}} <span class="counter">({{list.countFilms}}
                  {{ 'lamentaliste.element' | translate }}<span *ngIf="list.countFilms > 1">s</span>)</span>
              </div>
            </ng-container>-->

          </div>
          <div fxFlex="0 1 calc(48% - 32px)" class="homeHeaderParts">
            <h1>Jeux</h1>
            <div>

            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tabBoldTitle"> Date </th>
                    <td mat-cell *matCellDef="let list"> {{list.pdate  | date:'dd-MM-yyyy'}} </td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Titre </th>
                    <td mat-cell *matCellDef="let list"> 
                      <span class="displayList2" (click)="openQuiz(list.id)">{{list.title}}</span> </td>
                </ng-container>
                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'lamentaliste.table.quiz.you' | translate }} </th>
                    <td mat-cell *matCellDef="let list" class="tabCenteredGreen"> {{list.score}} </td>
                </ng-container>
                <ng-container matColumnDef="averageScore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'lamentaliste.table.quiz.averageScore' | translate }} </th>
                    <td mat-cell *matCellDef="let list" class="tabCentered"> {{list.averageScore}} </td>
                </ng-container>
                <ng-container matColumnDef="nbPlayers">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'lamentaliste.table.quiz.player' | translate }} </th>
                    <td mat-cell *matCellDef="let list" class="tabCentered"> {{list.nbPlayers}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
       </div>
        <!--
          </div>
         entete part 2-->
        <!--
        <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

          <div fxFlex="0 1 calc(45% - 32px)" class="homeHeaderParts">
            Les listes en vedette
            <ng-container *ngFor="let list of notifiedLists; let i=index">
              <div *ngIf="i<3" class="displayList2" (click)="openList(list.id)">
                {{list.Nom}} <span class="counter">({{list.countFilms}}
                  {{ 'lamentaliste.element' | translate }}<span *ngIf="list.countFilms > 1">s</span>)</span>
              </div>
            </ng-container>
          </div>

          <div fxFlex="0 1 calc(45% - 32px)" class="homeHeaderParts">
            Les derniers quiz
            <ng-container *ngFor="let list of quizList; let i=index">
              <div *ngIf="i<3">
                {{list.pdate  | date:'dd-MM-yyyy'}} - <span class="displayList2"
                  (click)="openQuiz(list.id)">{{list.title}}</span>
                <span *ngIf="list.score" class="counter">
                  ({{ 'lamentaliste.games.quiz.displayScore' | translate }}{{list.score}} pts)
                  <span class="counter" *ngIf="list.nbPlayers > 0">
                    {{ 'lamentaliste.games.quiz.averageScore' | translate }} {{list.averageScore}}
                    {{ 'lamentaliste.games.quiz.averageScore2' | translate }} {{list.nbPlayers}}
                    <span *ngIf="list.nbPlayers == 1 ">{{ 'lamentaliste.games.quiz.player' | translate }} </span>
                    <span *ngIf="list.nbPlayers > 1 ">{{ 'lamentaliste.games.quiz.players' | translate }} </span>

                  </span>
                </span>
              </div>
            </ng-container>
          </div>

        </div>




      </mat-card>

      <mat-accordion *ngIf="slides">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <div *ngIf="userAvatar">
                <img class="avatarImg" [src]="userAvatar">
              </div>
              <span class="presentationTitle">{{ 'lamentaliste.presentationTitle' | translate }}&nbsp;</span>
              <a href="#/friends">
                <span class="friendInfo"
                  *ngIf="nbFriendRequest == 1">{{ 'lamentaliste.friendRequest' | translate }}</span>
                <span class="friendInfo"
                  *ngIf="nbFriendRequest > 1">{{ 'lamentaliste.multipleFriendRequest' | translate }}</span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>

          {{ 'lamentaliste.presentationText' | translate }}<br>
          {{ 'lamentaliste.presentationText2' | translate }}<br><br>
          <span class="presentation2">{{ 'lamentaliste.presentationText.legend' | translate }}</span><br><br>

          <mat-icon>people_outline</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.share.legend.private' | translate }}
          <br>
          <mat-icon>group</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.share.legend.public' | translate }}
          <br>
          <mat-icon>group_add</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.share.legend.privateCollaborative' | translate }}
          <br>
          <mat-icon>public</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.share.legend.publicCollaborative' | translate }}
          <br>
          <mat-icon>format_list_numbered</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.legend.isRanking' | translate }}

          <br><br><span
            class="presentation2">{{ 'lamentaliste.presentationText.legend.otherIcons' | translate }}</span><br>

          <br>
          <mat-icon>share</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.shareList' | translate }}
          <br>
          <mat-icon>movie</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.addMovie' | translate }}
          <br>
          <mat-icon>tv</mat-icon>&nbsp;&nbsp;{{ 'lamentaliste.addSerie' | translate }}


        </mat-expansion-panel>
        <br><br>

      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel *ngIf="quizList && sixdegreesList" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="list-title">
              <span>{{ 'lamentaliste.menu.theGames' | translate }}&nbsp;</span>&nbsp;&nbsp;
              <a href="#/quizmenu">
                <span class="friendInfo">{{ 'lamentaliste.menu.new' | translate }}</span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a href="#/quizmenu"><span
              class="presentation2">{{ 'lamentaliste.last.sixdegrees' | translate }}</span></a><br>
          <ng-container *ngFor="let list of sixdegreesList; let i=index">
            <div *ngIf="i<3">
              {{list.pdate  | date:'dd-MM-yyyy'}} - <span class="displayList2"
                (click)="openSixDegrees(list.person1, list.person2, list.id)">{{list.name}}</span>&nbsp;
              <span class="success"
                *ngIf="list.cptUser == 1">{{ 'lamentaliste.games.sixdegrees.success' | translate }}</span>
              <span class="counter"
                *ngIf="list.cptAll == 0">&nbsp;({{ 'lamentaliste.games.sixdegrees.success0' | translate }})</span>
              <span class="counter"
                *ngIf="list.cptAll == 1">&nbsp;({{ 'lamentaliste.games.sixdegrees.success1' | translate }})</span>
              <span class="counter"
                *ngIf="list.cptAll > 1">&nbsp;({{list.cptAll}}{{ 'lamentaliste.games.sixdegrees.successMultiple' | translate }})</span>
            </div>
          </ng-container>
          <br>
          <a href="#/quizmenu"><span class="presentation2">{{ 'lamentaliste.last.quiz' | translate }}</span></a><br>
          <ng-container *ngFor="let list of quizList; let i=index">
            <div *ngIf="i<3">
              {{list.pdate  | date:'dd-MM-yyyy'}} - <span class="displayList2"
                (click)="openQuiz(list.id)">{{list.title}}</span>
              <span *ngIf="list.score" class="counter">
                ({{ 'lamentaliste.games.quiz.displayScore' | translate }}{{list.score}} pts)
                <span class="counter" *ngIf="list.nbPlayers > 0">
                  {{ 'lamentaliste.games.quiz.averageScore' | translate }} {{list.averageScore}}
                  {{ 'lamentaliste.games.quiz.averageScore2' | translate }} {{list.nbPlayers}}
                  <span *ngIf="list.nbPlayers == 1 ">{{ 'lamentaliste.games.quiz.player' | translate }} </span>
                  <span *ngIf="list.nbPlayers > 1 ">{{ 'lamentaliste.games.quiz.players' | translate }} </span>

                </span>
              </span>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
    -->
  </div>
