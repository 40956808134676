import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lamentaliste-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  userId: string = '';
  userPassword: string = '';
  userEmail: string = '';
  isCaptchaWaiting: boolean = true;
  constructor(    
    private data: DataService,
    public snackBar: MatSnackBar
    ) {}

  ngOnInit() {}

  createAccount() {
    this.data.createAccount(this.userId, this.userPassword, this.userEmail).subscribe(s => {
      console.log(s);
      if (s['status'] == 'error') {
        this.snackBar.open(
          'Ce pseudo est déjà utilisé',
          null,
          {
            duration: 2000,
            panelClass: 'snackbarError'
          }
        );
      } else if (s['status'] == 'success') {
        this.snackBar.open('Votre compte a été créé avec succès,' + this.userId, null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });
        window.location.href = '#/login';
      }
    });
  }

  public resolved(captchaResponse: string) {
    if( captchaResponse === null ){
      this.isCaptchaWaiting = true;
    } else {
      this.isCaptchaWaiting = false;
    }
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public isSubmitForgottenPasswDisabled() : Boolean {
    return this.isCaptchaWaiting || this.userId == "" || this.userPassword == "";
  }
}
