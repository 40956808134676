<div class="background">
    <div class="gradient">
      <div  class="container backgroundPanel">
      <h1>{{ 'lamentaliste.title.login' | translate }}</h1>
      <form (ngSubmit)="userLogin()" *ngIf="!isForgottenPassword">
      <div class="formFields">
        <mat-form-field class="mat-input-placeholder">
            <input [(ngModel)]="userId" name="userId" matInput  placeholder="{{ 'lamentaliste.label.id' | translate}}">
        </mat-form-field>
        <br><br>
        <mat-form-field class="mat-input-placeholder">
            <input [(ngModel)]="userPassword" name="userPassword" type="password" matInput  placeholder="{{ 'lamentaliste.label.password' | translate}}">
        </mat-form-field>
        <br><br>
        <button type="submit" mat-raised-button color="accent">
            {{ 'lamentaliste.label.connect' | translate}}
        </button>
        <br><br>
        <a href="./#/createAccount">{{ 'lamentaliste.noAccount' | translate}}</a>
      </div>
      </form>
      <br>
      <br>
      <div class="formFields">
        <button mat-stroked-button *ngIf="!isForgottenPassword"
        (click)="updateForgottenPassword(true)">{{ 'lamentaliste.showForgottenPassword' | translate }}</button>  
        <div *ngIf="isForgottenPassword">
          {{ 'lamentaliste.forgottenPasswordText' | translate}}
          <form (ngSubmit)="sendForgottenPassword()">
            <mat-form-field class="mat-input-placeholder">
              <input [(ngModel)]="userPasswPseudo" name="userPasswPseudo" matInput  placeholder="{{ 'lamentaliste.label.id' | translate}}">
            </mat-form-field>
              <br><br>
            <mat-form-field class="mat-input-placeholder">
              <input tpye="email" [(ngModel)]="userEmail" name="userEmail" matInput placeholder="{{ 'lamentaliste.label.email2' | translate}}" >
            </mat-form-field>
            <br><br>
            <button [disabled]="isSubmitForgottenPasswDisabled()" type="submit" mat-raised-button color="accent">
              {{ 'lamentaliste.label.send' | translate}}
          </button>
          <br><br>
          <div class="text-xs-center">
            <re-captcha class="g-recaptcha" (resolved)="resolved($event)" siteKey="6Lc0YdgUAAAAAJD1F_1uTSEulPVZmKKVS2ixw6QV"></re-captcha>
          </div>
          </form>
          <br><br>
          <button mat-stroked-button 
          (click)="updateForgottenPassword(false)">{{ 'lamentaliste.hideForgottenPassword' | translate }}</button>
        </div>
      </div>
  </div>
  </div>
</div>

  
