import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

class Question{
  question : string;
  prop1 : string;
  prop2 : string;
  prop3 : string;
  image : string;
  answer : string;
}

class Quiz {
  id : string;
  title : string;
  qList : Question[];
}

@Component({
  selector: 'lamentaliste-quizmenu',
  templateUrl: './quizmenu.component.html',
  styleUrls: ['./quizmenu.component.scss']
})
export class QuizmenuComponent implements OnInit {
  public newQuiz : Quiz = new Quiz();
  public sixdegrees : Object[];
  public quiz : Object[];
  public extract : Object[];
  public userId : String;
  public classement : Object[];
  public nbClassementShow : string; 
  public classementQuiz : Object[];
  public nbClassementQuizShow : string; 
  public showAllSixDegrees : boolean = false;
  public showAllQuiz : boolean = false;
  public showAllExtracts : boolean = false;
  public lastSixDegrees : Object;
  public lastQuiz : Object;
  public lastExtract : Object;

  constructor(
    private data: DataService
  ) { 
    this.userId = data.getUserInfos();
  }

  ngOnInit() {
    this.getSixDegrees();
    this.getQuizList();
    this.generateQuiz();
    this.getExtractList();
  }

  public openSixDegrees(id1: string, id2: string, game: string){
    window.location.href = '#/sixdegrees?id1=' + id1 + '&id2=' + id2 + '&game=' + game;
  }

  public openQuiz(id: string){
    window.location.href = '#/quiz?id=' + id;
  }

  public openExtract(id: string){
    window.location.href = '#/extract?id=' + id;
  }

  public getSixDegrees( ){
    this.data.getSixDegrees().subscribe(rep => {
      this.sixdegrees = Object.assign([], rep);
      this.lastSixDegrees = this.sixdegrees[0];
    });
  }

  public showClassement(game : string){
    this.data.getSixDegreesTable(game).subscribe(rep => {
      this.classement = Object.assign([], rep);
      if( this.nbClassementShow == game ){
        this.nbClassementShow = "";
      } else {
        this.nbClassementShow = game;
      }
    });
  }

  public showClassementQuiz(game : string){
    this.data.getQuizTable(game).subscribe(rep => {
      this.classementQuiz = Object.assign([], rep);
      if( this.nbClassementQuizShow == game ){
        this.nbClassementQuizShow = "";
      } else {
        this.nbClassementQuizShow = game;
      }
    });
  }

  public updateShowAllSixDegrees( show : boolean){
    this.showAllSixDegrees = show;
  }

  public updateShowAllQuiz( show : boolean){
    this.showAllQuiz = show;
  }

  public updateShowAllExtracts( show : boolean){
    this.showAllExtracts = show;
  }

  public getQuizList(){
    this.data.getQuizList().subscribe(rep => {    
      this.quiz = Object.assign([], rep);
      this.lastQuiz = this.quiz[0];
    });
  }

  public getExtractList( ){
    this.data.getExtractList().subscribe(rep => {
      this.extract = Object.assign([], rep);
      this.lastExtract = this.extract[0];
    });
  }


  public generateQuiz(){
    this.newQuiz.qList = Array(10);
    for (let i = 0; i < 10; i++) {
      this.newQuiz.qList[i] = new Question();
    }  
  }

  public saveNewQuiz(){
    this.data.saveNewQuiz(this.newQuiz.id, this.newQuiz.title, this.newQuiz.qList).subscribe(rep => {    
      console.log(rep);
      for (let i = 0; i < 10; i++) {
        console.log(this.newQuiz.qList[i].question);
        console.log(this.newQuiz.qList[i]);
        console.log(this.newQuiz.qList);
        this.data.saveNewQuizQuestion(
          this.newQuiz.id,
          i+1,
          this.newQuiz.qList[i].question,
          this.newQuiz.qList[i].prop1,
          this.newQuiz.qList[i].prop2,
          this.newQuiz.qList[i].prop3,
          this.newQuiz.qList[i].image,
          this.newQuiz.qList[i].answer
          ).subscribe(rep => {
          });            
      }
    });
  }


}
