import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectAuth, AppState } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  storeSubscription: Subscription;
  user: String[] = [];

  constructor(
    private http: HttpClient,     
    private store: Store<AppState>) {
      this.store
      .pipe(select(selectAuth))
      .subscribe(auth => {
        if( auth.userId != undefined && auth.userId != '' ){
          this.user[0] = auth.userId;
          this.user[1] = auth.userName;
        } else {
          this.user[0] = '215';
          this.user[1] = 'Visiteur';
        }
        localStorage.setItem('currentUser', JSON.stringify(this.user));
    });
    }

  login(username: string, password: string) {
    return this.http
      .post<any>(`/session`, { username: username, password: password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
          }
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  getUserInfos() : String[] {
    return this.user;
  }
}
