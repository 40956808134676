import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

class Question{
  question : string;
  prop1 : string;
  prop2 : string;
  prop3 : string;
  image : string;
  answer : string;
  rep : string;
}

class Quiz {
  id : string;
  title : string;
  qList : Question[];
}


@Component({
  selector: 'lamentaliste-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  public id: string;
  public quiz: Quiz;
  public finalScore : number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.activatedRoute.fragment.subscribe(fragment => { });
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'];
      this.getQuiz();
    });
  }

  private getQuiz(){
    this.data.getQuiz(this.id).subscribe(res => {
      console.log(res);
      this.quiz = new Quiz();
      this.quiz.id = res["0"].id;
      this.quiz.title = res["0"].title;
      this.getQuestions();
    });
  }

  private getQuestions(){
    this.data.getQuizQuestions(this.id).subscribe(res => {
    this.quiz.qList = Array();
    console.log(res);
    for (let obj of res) {
      let question : Question = new Question;
      question.question = obj["question"];
      question.prop1 = obj["prop1"];
      question.prop2 = obj["prop2"];
      question.prop3 = obj["prop3"];
      question.answer = obj["answer"];
      question.image = obj["image"];
      this.quiz.qList.push(question);
    }
  });

  }

  private errorMsg() {
    this.snackBar.open(
      "Vous n'avez pas répondu à toutes les question.",
      null,
      {
        duration: 2000,
        panelClass: 'snackbarError'
      }
    );
  }

  private displayScore(score : number) {
    this.snackBar.open(
      "Votre score : " + score + " points",
      null,
      {
        duration: 2000,
        panelClass: 'snackbarOK'
      }
    );
  }

  public validateQuiz() {
    let score : number = 0;
    let notCompleted : boolean = false;
    for (let obj of this.quiz.qList){
      let question : Question = obj;
      if( question.rep == null ){
        notCompleted = true;
      } else if( question.rep == question.answer){
        score++;
      }
    }
    if( notCompleted == false){
      this.saveQuizResult(score);
    } else {
      this.errorMsg();
    }
  }

  public saveQuizResult(score : number){
    this.displayScore(score);
    this.finalScore = score;
    this.data.saveQuizResult(this.quiz.id, score).subscribe(rep => {
    });
  }
}
