<div class="container">
  <div class="row">
    <div class="col-sm-12"><h1>{{ 'lamentaliste.settings.title' | translate }}</h1></div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-6 group">
      <h2>{{ 'lamentaliste.settings.general' | translate }}</h2>
      <div class="icon-form-field">
        <mat-icon color="accent"><i class="fa fa-language" color="accent"></i></mat-icon>
        <mat-form-field>
          <mat-select [placeholder]="'lamentaliste.settings.general.placeholder' | translate"
                      [ngModel]="settings?.language"
                      (selectionChange)="onLanguageSelect($event)"
                      name="language">
            <mat-option *ngFor="let l of languages" [value]="l.value">
              {{ 'lamentaliste.settings.general.language.' + l.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="icon-form-field">
         <mat-icon color="accent"><i class="fa fa-bars" color="accent"></i></mat-icon>
         <mat-placeholder>{{ 'lamentaliste.settings.themes.sticky-header' | translate }}
         </mat-placeholder>
         <mat-slide-toggle
           [checked]="settings?.stickyHeader"
           (change)="onStickyHeaderToggle($event)">
         </mat-slide-toggle>
       </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 group">
      <h2>{{ 'lamentaliste.settings.themes' | translate }}</h2>
      <div class="icon-form-field">
        <mat-icon color="accent"><i class="fa fa-paint-brush" color="accent"></i></mat-icon>
        <mat-form-field>
          <mat-select [placeholder]="'lamentaliste.settings.themes.placeholder' | translate"
                      [ngModel]="settings?.theme"
                      (selectionChange)="onThemeSelect($event)"
                      name="themes">
            <mat-option *ngFor="let t of themes" [value]="t.value">
              {{ 'lamentaliste.settings.themes.' + t.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="icon-form-field">
        <mat-icon color="accent"><i class="fa fa-lightbulb" color="accent"></i></mat-icon>
        <mat-placeholder>{{ 'lamentaliste.settings.themes.night-mode' | translate }}
        </mat-placeholder>
        <mat-slide-toggle
          [checked]="settings?.autoNightMode"
          (change)="onAutoNightModeToggle($event)">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="col-md-6 group">
      <h2>{{ 'lamentaliste.settings.animations' | translate }}</h2>
      <div class="icon-form-field">
        <mat-icon color="accent"><mat-icon color="accent"><i class="fa fa-window-maximize"></i></mat-icon></mat-icon>
        <mat-placeholder>{{ 'lamentaliste.settings.animations.page' | translate }}
        </mat-placeholder>
        <mat-slide-toggle
          matTooltip="Sorry, this feature is disabled in IE, EDGE and Safari"
          matTooltipPosition="before"
          *ngIf="settings?.pageAnimationsDisabled"
          disabled>
        </mat-slide-toggle>
        <mat-slide-toggle
          *ngIf="!settings?.pageAnimationsDisabled"
          [checked]="settings?.pageAnimations"
          (change)="onPageAnimationsToggle($event)">
        </mat-slide-toggle>
      </div>
      <div class="icon-form-field">
        <mat-icon color="accent"><i class="fa fa-stream" color="accent"></i></mat-icon>
        <mat-placeholder>{{ 'lamentaliste.settings.animations.elements' | translate }}
        </mat-placeholder>
        <mat-slide-toggle
          [checked]="settings?.elementsAnimations"
          (change)="onElementsAnimationsToggle($event)">
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
