import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
class AuthInfos {
  connectedUser: string;
  owner:string;
  ownerName:string;
  deleted:string;
  public:string;
  friend:string;
  listName:string;
  isranking:boolean;
  aime:string;
  cptLikes:string;
}

@Component({
  selector: 'lamentaliste-list-content',
  templateUrl: './list-content.component.html',
  styleUrls: ['./list-content.component.scss']
})

export class ListContentComponent implements OnInit {
  public listId: string;
  public movieId: string;     // when coming back from movie detail, in order to get same position.
  public items : Object[];
  public authInfos : AuthInfos;
  public userId : string;
  public authLevel : string;    // 2 : all access (owner only)
                                // 1 : modifs authorized (colaborative)
                                // 0 : view only
  public showAddMovie : boolean = false;
  public showAddTV : boolean = false;
  public showShareZone : boolean = false;
  public showRanking : boolean = true;
  public like : boolean = false;
  public cptLikes : number;
  public showImage : boolean = true;
  public showRename : boolean = false;
  public newListName : string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    public snackBar: MatSnackBar
  ) { }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  ngOnInit() {
    this.activatedRoute.fragment.subscribe(fragment => { });
    this.activatedRoute.params.subscribe(queryparams => {
      console.log(queryparams);
    })

    this.activatedRoute.queryParams.subscribe(params => {
      this.listId = params['listId'];
      this.movieId = params['movieId'];
      this.data.getListAuthorization(this.listId).subscribe(repAuth => {
      this.authInfos = repAuth[0];
      this.userId = this.authInfos.connectedUser; 
        this.cptLikes = parseInt(this.authInfos.cptLikes);
        this.newListName = this.authInfos.listName;
        this.like = this.authInfos.aime == '1';
        this.showRanking = this.authInfos.isranking;
        if( this.authInfos.deleted == '1' ||         // list deleted = no access authorized
            ( this.authInfos.connectedUser != this.authInfos.owner        // not the owner
              && this.authInfos.connectedUser != this.authInfos.friend    // not a friend with whom the list is shared
              && (this.authInfos.public == '0' || this.authInfos.public == '2')    // list is not public
            )     
        ){
          this.snackBar.open(
            'You are not authorized to view this page !',
            null,
            {
              duration: 2000,
              panelClass: 'snackbarError'
            }
          );  
          window.location.href = '#/home';
        } else { // authorized to view
          if( this.authInfos.connectedUser == this.authInfos.owner ) {
            this.authLevel = '2';
          } else if( 
            (this.authInfos.public == '2' && this.authInfos.friend != null ) ||
            (this.authInfos.public == '3') ){
            this.authLevel = '1';
          } else this.authLevel = '0';
            this.getListContent();
        }
      });
    });
  }

  private getListContent(){
    this.data.getListContent(this.listId, this.showRanking).subscribe(rep => {
      console.log(rep);
      this.items = Object.assign([], rep);
      if( this.movieId != undefined ) {
        try {
          setTimeout(() =>
            document.getElementById(this.movieId).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}),
            1000);
        } catch (e) { }
      }
    });  
  }

  /*
    gets authorization on list
    0 : no authorization, should not happen
    1 : list owner
    2 : shared with me
    3 : public list
  */
  getListAuthorization(){
    this.data.getListAuthorization(this.listId).subscribe(rep => {
      console.log(rep);
    });
  }

  public addItem(newItem : any){
    this.items.unshift(newItem);
    console.log(this.items);
  }
  
  public delClicked(item : any){
    this.data.deleteItem(item.Id).subscribe(res => {
      console.log(res);
      const index: number = this.items.indexOf(item);
      console.log(index);
      if (index !== -1) {
          this.items.splice(index, 1);
      }  
    })
  }

  public deleteList(){    
    if(confirm("Etes-vous sûr de vouloir supprimer la liste ?")) {
      this.data.deleteList(this.listId).subscribe(res => {
        console.log(res); 
        window.location.href = '#/home';
      })
    }
  }

  public renameList(){
    this.data.renameList(this.listId, this.newListName).subscribe(res => {
      this.authInfos.listName = this.newListName;
      this.showRename = false;
      this.snackBar.open('Modifications enregistrées', null, {
        duration: 2000,
        panelClass: 'snackbarOK'
      });
  });
  }

  private saveRanking(){
    console.log(this.items);
    this.data.saveRanking(this.listId, this.items).subscribe(res => {
        this.snackBar.open('Modifications enregistrées', null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });
    });
  }
  
  private onShowRanking(){
    this.showRanking = !this.showRanking;
    this.data.makeListRanking(this.listId, this.showRanking).subscribe(res => {
    });
    this.getListContent();

  }

  private likeList(value: string){
    if( value == '1'){
      this.cptLikes++;
    } else {
      this.cptLikes--;
    }
    this.like = value == '1';
    this.data.likeList(this.listId , value).subscribe(repAuth => {
      if( this.like ){
        this.snackBar.open('Vous aimez cette liste', null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });  
      } else {
        this.snackBar.open("Vous n'aimez plus cette liste", null, {
          duration: 2000,
          panelClass: 'snackbarOK'
        });  
      }
  });    
  }

  public updatePublicStatus(){
    this.data.getListAuthorization(this.listId).subscribe(repAuth => {
      this.authInfos = repAuth[0];      
    });    
  }

  public onAddMovieButton(){
    this.showAddTV = false;
    this.showAddMovie = !this.showAddMovie;
    this.showShareZone = false;
  }

  public onAddTVButton(){
    this.showAddMovie = false;
    this.showAddTV = !this.showAddTV;
    this.showShareZone = false;
  }

  public closeAddZone(){
    this.showAddMovie = false;
    this.showAddTV = false;
    this.showShareZone = false;
  }

  public closeShareZone(){
    this.showShareZone = false;
  }

  public onShareListButton(){
    this.showAddTV = false;
    this.showAddMovie = false;
    this.showShareZone = !this.showShareZone;
  }

  public onShowImage(){
    this.showImage = !this.showImage;
  }

}
