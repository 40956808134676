<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="background">
  <div class="gradient">
    <div class="container backgroundPanel friends">
      <h1>Amis</h1>
      <div *ngIf="isVisitor">
          {{ 'lamentaliste.noAccessForVisitor' | translate}}
      </div>
      <div *ngIf="!isVisitor" class="alignleft">
        <div *ngIf="friendsWaitingVal != undefined && friendsToValidate != undefined && friends != undefined">
          <br>
          <mat-form-field>
            <input [(ngModel)]="friendToAdd" type="text" matInput
              placeholder=" {{ 'lamentaliste.addFriend' | translate }} ">
          </mat-form-field>&nbsp;&nbsp;
          <button mat-raised-button color="accent" (click)="addFriend()">
            {{ 'lamentaliste.label.add' | translate}}
          </button>
          <div>
            <br>
            <b>{{ 'lamentaliste.myFriends' | translate}}</b>
          </div>
          <div *ngIf="friends.length == 0">
            {{ 'lamentaliste.noFriendYet' | translate}}
          </div>
          <div *ngFor="let friend of friends">
            <button title="{{ 'lamentaliste.removeFriendship' | translate }}" mat-button class="menuIcon"
              (click)="removeFreindship(friend.ami)" color="primary">
              <mat-icon>cancel</mat-icon>
            </button>
            {{friend.pseudo}}
          </div>
          <div *ngIf="friendsToValidate.length > 0">
            <br>
            <b>{{ 'lamentaliste.friendsToValidate' | translate}}</b>
            <div *ngFor="let friend of friendsToValidate">
              {{friend.pseudo}}
              <button title="{{ 'lamentaliste.accept' | translate }}" mat-button class="menuIcon"
                (click)="updateFriendship(friend.ami1, friend.ami2, 1)" color="primary">
                <mat-icon>thumb_up_alt</mat-icon>
              </button>
              <button title="{{ 'lamentaliste.decline' | translate }}" mat-button class="menuIcon"
                (click)="updateFriendship(friend.ami1, friend.ami2, -1)" color="primary">
                <mat-icon>thumb_down_alt</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="friendsWaitingVal.length > 0">
            <br>
            <b>{{ 'lamentaliste.friendsWaitingValidation' | translate}}</b>
            <div *ngFor="let friend of friendsWaitingVal">
              <button title="{{ 'lamentaliste.removeFriendship' | translate }}" mat-button class="menuIcon"
                (click)="updateFriendship(friend.ami1, friend.ami2, -1)" color="primary">
                <mat-icon>cancel</mat-icon>
              </button>
              {{friend.pseudo}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>