<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="background">
  <div class="gradient" *ngIf="movieInfos">
    <div class="container backgroundPanel">
      <div>
        <div  [style.background-image]="backgroundImage" class="backgroundStyle">
        </div>  
        <table>
          <tr>
            <td>
              <button mat-fab color="primary" (click)="backClicked()">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </td>
            <td class="movieTitle">
              <span>{{movieInfos.title}}</span>
            </td>
          </tr>
        </table>
        <br>
        <table>
          <tr>
            <td>
              <a href="https://image.tmdb.org/t/p/original{{movieInfos.poster_path}}" target="_blank">
                <img class="img2" src="https://image.tmdb.org/t/p/w185_and_h278_bestv2{{movieInfos.poster_path}}"
                  height="250" style="border:10px solid"></a>&nbsp;
            </td>
            <td class="movieDetails">
              <div>
                <span class="detailTitle">{{ 'lamentaliste.year' | translate }}</span>
                {{movieInfos.release_date.substring(4, 0)}}
              </div>
              <div *ngIf="movieInfos">
                <span class="detailTitle">{{ 'lamentaliste.genre' | translate }}</span>
                <span *ngFor="let genre of movieInfos.genres">
                  {{genre.name}}
                </span>
              </div>
              <div *ngIf="movieInfos">
                <span class="detailTitle">{{ 'lamentaliste.country' | translate }}</span>
                <span *ngFor="let country of movieInfos.production_countries">
                  {{country.iso_3166_1}}
                </span>
              </div>
              <div>
                <span *ngIf="lamentaliste" class="detailTitle">{{ 'lamentaliste.length' | translate }}</span>
                {{movieInfos.runtime}}{{ 'lamentaliste.minutes' | translate }}
              </div>
              <br>
              <div>
                <a href="http://www.themoviedb.org/movie/{{movieInfos.id}}" target="_blank">
                <img class="logo" src="assets/tmdblogo.svg"></a>
                <b>{{movieInfos.vote_average}}</b> ({{movieInfos.vote_count}} {{ 'lamentaliste.votes' | translate }})
              </div>
              <div *ngIf="imdbRating">
                <a href="https://www.imdb.com/title/{{movieInfos.imdb_id}}" target="_blank">
                  <img class="logo" src="assets/imdb.png"></a>
                <b>{{imdbRating[0]}}</b> ({{imdbRating[1]}} {{ 'lamentaliste.votes' | translate }})
              </div>
              <div *ngIf="movieProviders && movieProviders.results && movieProviders.results.FR && movieProviders.results.FR.flatrate">
                <span class="providerTitleEnTete">{{ 'lamentaliste.flatrate' | translate }}</span>
                <span *ngFor="let providers of movieProviders.results.FR.flatrate">
                  <span matTooltip="{{providers.provider_name}}">
                    <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
                  </span>
                </span>
              </div>
              <div *ngIf="!movieProviders || !movieProviders.results || !movieProviders.results.FR || !movieProviders.results.FR.flatrate">
                <span class="providerTitleEnTete">{{ 'lamentaliste.flatrate.notavailable' | translate }}</span>
              </div>
              <div>
                <form (ngSubmit)="addToList(selectedListMovie)">
                  <mat-form-field>
                  <mat-label>Envoyer vers une liste</mat-label>
                  <mat-select [(value)]="selectedListMovie">
                    <mat-option *ngFor="let item of userLists" [value]="item.id">
                      {{item.Nom}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-raised-button color="accent" *ngIf="selectedListMovie != null">
                  {{ 'lamentaliste.label.add' | translate}}
                </button>
              </form>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <!-- ***************************** FIN EN-TETE  ***************************************************************************-->
      <mat-tab-group class="backgroundPanel">
        <mat-tab label="{{ 'lamentaliste.resume' | translate }}"> <br>
          <p class="overview">{{movieInfos.overview}}</p>
        </mat-tab>
        <mat-tab *ngIf="movieCredits" label="{{ 'lamentaliste.distribution' | translate }}"><br>
          <div *ngFor="let item of movieCredits.crew">
            <div *ngIf="item.job == 'Director'"><b>{{ 'lamentaliste.director' | translate }} : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Producer'"><b>{{ 'lamentaliste.producer' | translate }} : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Executive Producer'"><b>{{ 'lamentaliste.executiveProducer' | translate }} : </b>
              <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Original Music Composer'"><b>{{ 'lamentaliste.originalMusicComposer' | translate }}
                : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Writer'"><b>{{ 'lamentaliste.writer' | translate }} : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
          </div>
          <br>

          <span class='detailSubTitle'>{{ 'lamentaliste.cast' | translate }}</span>
          <br>
          <div *ngFor="let item of movieCredits.cast">
            <b><a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> : </b>{{item.character}}
          </div>
        </mat-tab>
        <mat-tab *ngIf="movieImages" label="{{ 'lamentaliste.images' | translate }}"><br>
          <a href="https://image.tmdb.org/t/p/original{{movieInfos.poster_path}}" target="_blank">
            <img class="image" src="http://image.tmdb.org/t/p/w500{{movieInfos.poster_path}}">
          </a>
          <span *ngFor="let image of movieImages.posters">
            <a href="http://image.tmdb.org/t/p/original{{image.file_path}}" target="_blank">
              <img class="image" src="http://image.tmdb.org/t/p/w500{{image.file_path}}">
            </a>
          </span>
        </mat-tab>
        <mat-tab *ngIf="movieVideos" label="{{ 'lamentaliste.videos' | translate }}"> <br>
          <div *ngIf="movieVideos.results.length == 0">
            <div *ngFor="let video of youtube">
                <iframe width="560" height="315" [src]="getIframeSrc(video.id.videoId)" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
          <div *ngFor="let video of movieVideos.results">
            <iframe width="560" height="315" [src]="getIframeSrc(video.key)" frameborder="0" allowfullscreen></iframe>
          </div>
        </mat-tab>

        <!--================================-->

        <mat-tab *ngIf="movieProviders && movieProviders.results && movieProviders.results.FR" label="{{ 'lamentaliste.providers' | translate }}"> <br>
          <br><span *ngIf="movieProviders.results.FR.buy" class="providerTitle">{{ 'lamentaliste.buy' | translate }}<br></span>
        <span *ngFor="let providers of movieProviders.results.FR.buy">
          <span matTooltip="{{providers.provider_name}}">
            <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
            </span>
        </span>
        <br><span *ngIf="movieProviders.results.FR.flatrate" class="providerTitle">{{ 'lamentaliste.flatrate' | translate }}<br></span>
        <span *ngFor="let providers of movieProviders.results.FR.flatrate">
          <span matTooltip="{{providers.provider_name}}">
            <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
          </span>
        </span>
        <br><span *ngIf="movieProviders.results.FR.rent" class="providerTitle">{{ 'lamentaliste.rent' | translate }}<br></span>
        <span *ngFor="let providers of movieProviders.results.FR.rent">
          <span matTooltip="{{providers.provider_name}}">
            <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
          </span>
        </span>
      </mat-tab>
       <!--================================-->
      </mat-tab-group>
      <br>
      <i style="color : grey">Les informations sur cette page sont issues du site themoviedb.org</i>
    </div>
  </div>
  <!----------------------------------------------------------------------------->
  <div class="gradient" *ngIf="tvInfos">
    <div class="container backgroundPanel">
      <div>
        <table>
          <tr>
            <td>
               <button mat-fab color="primary" (click)="backClicked()">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </td>
            <td class="movieTitle">
              <span>{{tvInfos.name}}</span>
            </td>
          </tr>
        </table>
        <br>
        <table>
          <tr>
            <td>
              <a href="https://image.tmdb.org/t/p/original{{tvInfos.poster_path}}" target="_blank">
                <img class="img2" src="https://image.tmdb.org/t/p/w185_and_h278_bestv2{{tvInfos.poster_path}}"
                  height="250" style="border:10px solid"></a>&nbsp;
            </td>
            <td class="movieDetails">
              <div>
                <span class="detailTitle">{{ 'lamentaliste.year' | translate }}</span>
                {{tvInfos.first_air_date.substring(4, 0)}}
              </div>
              <div *ngIf="tvInfos">
                <div>
                  <span class="detailTitle">{{ 'lamentaliste.genre' | translate }}</span>
                  <span *ngFor="let genre of tvInfos.genres">
                    {{genre.name}}
                  </span>
                </div>
                <div>
                  <span class="detailTitle">{{ 'lamentaliste.episodeRuntime' | translate }}</span>
                  <span *ngFor="let runtime of tvInfos.episode_run_time">
                    {{runtime}}&nbsp;minutes
                  </span>
                </div>
                <div>
                  <span class="detailTitle">{{ 'lamentaliste.nbSeasons' | translate }}</span>
                  {{tvInfos.number_of_seasons}}
                </div>
                <div>
                  <span class="detailTitle">{{ 'lamentaliste.nbEpisodes' | translate }}</span>
                  {{tvInfos.number_of_episodes}}
                </div>
                <div>
                  <span class="detailTitle">{{ 'lamentaliste.country' | translate }}</span>
                  <span *ngFor="let country of tvInfos.origin_country">
                    {{country}}
                  </span>
                </div>
                <div>
                  <span class="detailTitle">{{ 'lamentaliste.status' | translate }}</span>
                  <span *ngIf="tvInfos.status == 'Canceled'">
                      {{'lamentaliste.status.stopped' | translate}}
                    </span>                 
                     <span *ngIf="tvInfos.status == 'Ended'">
                        {{'lamentaliste.status.ended' | translate}}
                      </span>
                  <span *ngIf="tvInfos.status != 'Canceled' && tvInfos.status != 'Ended'">
                    {{'lamentaliste.status.onair' | translate}}
                  </span>
                  <br><br>
                  <div>
                    <a href="http://www.themoviedb.org/tv/{{tvInfos.id}}" target="_blank">
                    <img class="logo" src="assets/tmdblogo.svg"></a>
                    <b>{{tvInfos.vote_average}}</b> ({{tvInfos.vote_count}} {{ 'lamentaliste.votes' | translate }})
                  </div>    
                  <div *ngIf="imdbRating">
                    <a href="https://www.imdb.com/title/{{externalIds.imdb_id}}" target="_blank">
                      <img class="logo" src="assets/imdb.png"></a>
                    <b>{{imdbRating[0]}}</b> ({{imdbRating[1]}} {{ 'lamentaliste.votes' | translate }})
                  </div>
                </div>
              </div>
              <div *ngIf="tvProviders && tvProviders.results && tvProviders.results.FR && tvProviders.results.FR.flatrate">
                <span class="providerTitleEnTete">{{ 'lamentaliste.flatrate' | translate }}</span>
                <span *ngFor="let providers of tvProviders.results.FR.flatrate">
                  <span matTooltip="{{providers.provider_name}}">
                    <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
                  </span>
                </span>
              </div>
              <div *ngIf="!tvProviders || !tvProviders.results || !tvProviders.results.FR || !tvProviders.results.FR.flatrate">
                <span class="providerTitleEnTete">{{ 'lamentaliste.flatrate.notavailable' | translate }}</span>
              </div>
              <div>
                <form (ngSubmit)="addToList(selectedListMovie)">
                  <mat-form-field>
                  <mat-label>Envoyer vers une liste</mat-label>
                  <mat-select [(value)]="selectedListMovie">
                    <mat-option *ngFor="let item of userLists" [value]="item.id">
                      {{item.Nom}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-raised-button color="accent" *ngIf="selectedListMovie != null">
                  {{ 'lamentaliste.label.add' | translate}}
                </button>
              </form>
              </div>
            </td>
          </tr>
        </table>
      </div>



      <mat-tab-group class="backgroundPanel">
        <mat-tab label="{{ 'lamentaliste.resume' | translate }}"> <br>
          <p class="overview">{{tvInfos.overview}}</p>
        </mat-tab>
        <mat-tab label="{{ 'lamentaliste.seasons' | translate }}"> <br>
          <div *ngFor="let season of tvInfos.seasons">
            <div class="seasonName">
              {{season.name}}
            </div>
            <div *ngIf="season.air_date != null"> 
              <span class="detailTitle">{{ 'lamentaliste.year' | translate }}</span>
              {{season.air_date.substring(4, 0)}}
            </div>
            <div>
                <span class="detailTitle">{{ 'lamentaliste.nbEpisodes' | translate }}</span>
              {{season.episode_count}}
            </div>
            <a href="https://image.tmdb.org/t/p/original{{season.poster_path}}" target="_blank">
            <img class="img2" src="https://image.tmdb.org/t/p/w185_and_h278_bestv2{{season.poster_path}}"
              height="250" style="border:10px solid"></a>
              <br><br>
          </div>

        </mat-tab>
        <mat-tab *ngIf="tvCredits" label="{{ 'lamentaliste.distribution' | translate }}"><br>
          <div *ngFor="let item of tvCredits.crew">
            <div *ngIf="item.job == 'Director'"><b>{{ 'lamentaliste.director' | translate }} : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Producer'"><b>{{ 'lamentaliste.producer' | translate }} : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Executive Producer'"><b>{{ 'lamentaliste.executiveProducer' | translate }} : </b>
              <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Original Music Composer'"><b>{{ 'lamentaliste.originalMusicComposer' | translate }}
                : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
            <div *ngIf="item.job == 'Writer'"><b>{{ 'lamentaliste.writer' | translate }} : </b> <a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> </div>
          </div>
          <br>

          <span class='detailSubTitle'>{{ 'lamentaliste.cast' | translate }}</span>
          <br>
          <div *ngFor="let item of tvCredits.cast">
            <b><a href="https://www.themoviedb.org/person/{{item.id}}" target="_blank">{{item.name}}</a> : </b>{{item.character}}
          </div>
        </mat-tab>
        <mat-tab *ngIf="tvImages" label="{{ 'lamentaliste.images' | translate }}"><br>
          <a href="http://image.tmdb.org/t/p/w500{{tvInfos.backdrop_path}}" target="_blank">
          <img class="image" src="http://image.tmdb.org/t/p/w500{{tvInfos.backdrop_path}}">
        </a>
          <span *ngFor="let image of tvImages.posters">
            <a href="http://image.tmdb.org/t/p/w500{{image.file_path}}" target="_blank">
              <img class="image" src="http://image.tmdb.org/t/p/w500{{image.file_path}}">
            </a>
          </span>

        </mat-tab>
        <mat-tab *ngIf="tvVideos" label="{{ 'lamentaliste.videos' | translate }}"> <br>
          <div *ngIf="tvVideos.results.length == 0">{{ 'lamentaliste.noVideo' | translate }}<br><br>
              <div *ngFor="let video of youtube">
                  <iframe width="560" height="315" [src]="getIframeSrc(video.id.videoId)" frameborder="0" allowfullscreen></iframe>
              </div>  
          </div>
          <div *ngFor="let video of tvVideos.results">
            <iframe width="560" height="315" [src]="getIframeSrc(video.key)" frameborder="0" allowfullscreen></iframe>
          </div>
        </mat-tab>

        <mat-tab *ngIf="tvProviders && tvProviders.results && tvProviders.results.FR" label="{{ 'lamentaliste.providers' | translate }}"> <br>
          <br><span *ngIf="tvProviders.results.FR.buy" class="providerTitle">{{ 'lamentaliste.buy' | translate }}</span><br>
        <span *ngFor="let providers of tvProviders.results.FR.buy">
          <span matTooltip="{{providers.provider_name}}">
          <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
          </span>
        </span>
        <br><span *ngIf="tvProviders.results.FR.flatrate" class="providerTitle">{{ 'lamentaliste.flatrate' | translate }}<br></span>
        <span *ngFor="let providers of tvProviders.results.FR.flatrate">
          <span matTooltip="{{providers.provider_name}}">
            <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
          </span>
        </span>
        <br><span *ngIf="tvProviders.results.FR.rent" class="providerTitle">{{ 'lamentaliste.rent' | translate }}<br></span>
        <span *ngFor="let providers of tvProviders.results.FR.rent">
          <span matTooltip="{{providers.provider_name}}">
            <img (click)="goToLink(providers.provider_name)" class="imageProvider" src="http://image.tmdb.org/t/p/original/{{providers.logo_path}}">
          </span>
        </span>
      </mat-tab>
      </mat-tab-group>
      <br>
      <i style="color : grey">Les informations sur cette page sont issues du site themoviedb.org</i>
      </div>
  </div>
  <br><br><br><br><br><br><br>
</div>